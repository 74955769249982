import { Observable, interval, animationFrameScheduler, fromEvent } from 'rxjs'
import { finalize, take, share, mapTo } from 'rxjs/operators'

import { captureFrame } from './utils'

export const videoReady = video => fromEvent(video, 'canplay').pipe(
  mapTo(video), take(1), share()
)

export const track = (tracker, settings) => Observable.create(observer => {
  let frameId = null
  tracker.start()
    // console.log('%cTracking started...', 'color: mediumSeaGreen')

  const loop = () => {
    const frame = captureFrame(tracker.video, tracker.getCurrentPosition(), settings.userCaptureSize)
    observer.next(frame)
    frameId = window.requestAnimationFrame(loop)
  }

  frameId = window.requestAnimationFrame(loop)

  return () => {
    // console.log('%cTracking stopped...', 'color: tomato')
    tracker.stop()
    window.cancelAnimationFrame(frameId)
  }
})

// XXX Here for test purposes
export const singleCanvasCapture = (canvas, tracker, settings) => Observable.create(observer => {
  let frameId = null
  tracker.start()
  const ctx = canvas.getContext('2d')

  const loop = () => {
    // const frame = captureFrame(tracker.video, tracker.getCurrentPosition(), settings.userCaptureSize)
    ctx.drawImage(tracker.video, 0, 0)
    ctx.lineWidth = 4
    ctx.strokeStyle = '#00ff00'
    ctx.strokeRect(20, 20, canvas.width - 40, canvas.height - 40)

    observer.next({image: canvas, landmarks: tracker.getCurrentPosition()})
    frameId = window.requestAnimationFrame(loop)
  }

  frameId = window.requestAnimationFrame(loop)

  return () => {
    tracker.stop()
    window.cancelAnimationFrame(frameId)
  }
})
