import React, { createElement, createContext, useContext, useState } from 'react'
import translations from './translations'

const initalLanguage = document.querySelector('html').getAttribute('lang')

export const LanguageContext = createContext(initalLanguage)

export const translate = (key, props) => {
  const { language } = useContext(LanguageContext)
  const element = translations[language][key]
  return typeof(element) === 'string' ? element : createElement(element, props)
}

export const LanguageProvider = ({ children }) => {
  const [ language, setLanguage ] = useState(initalLanguage)
  return <LanguageContext.Provider value={{language, setLanguage}} children={children} />
}
