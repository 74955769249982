import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { utils } from 'pixi.js'
import { fromEvent } from 'rxjs'
import { debounceTime, map, startWith, tap } from 'rxjs/operators'

import { useAnalytics, useScreenview } from './analytics'
import { translate } from './language'
import { Mode } from '../chapter-01/constants'
import PageContainer from './ui/PageContainer'
import LanguageSwitcher from './LanguageSwitcher'
import CameraUsageAgreementScreen from './CameraUsageAgreementScreen'
import MainControl from '../chapter-01/MainControl'
import { PrimaryButtonArea, SecondaryButtonArea } from './layouts'
import { createTracking } from '../chapter-01/tracking'
import { isUsingMobileDevice } from '../lib/detect'
import LinkButton from './ui/LinkButton'
import InfoScreen from './InfoScreen'
import ModeSwitch from './ui/ModeSwitch'

utils.skipHello()

const trackingSettings = {
  userCaptureSize: 512,
  otherCaptureSize: 256, // Unused resizing of capture has been disabled
  captureAmount: 16,
  captureThrottleTime: 200,
  otherFetchInterval: 3000, // Interval between each load of a THEM tile
  mobileAlternateViewInterval: 5000,
  noDetectionTimeout: 2000, // Timeout before signaling that there are no landmarks detected
}

const StyledInfoButton = styled.button`
  position: absolute;
  left: 50%;
  bottom: 10%;
  color: white;
  background: black;
  font-size: 16px;
  text-decoration: underline;
  transform: translate(-50%, 0);
`

const StyledInstructionText = styled.div`
  color: red;
  font-size: 3em;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-transform: uppercase;
  text-align: center;
  transform: translateY(calc(-100% + 0.05em));
  pointer-events: none;
  opacity: 0;
  letter-spacing: 0.75em;
  line-height: 1;

  @media (max-width: 1024px) {
    font-size: 1.5em;
    transform: translateY(calc(-100% + 0.25em));
  }

  ${({ visible }) => visible && `
    opacity: 1;
  `}
`

const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  top: var(--margin);
  right: var(--margin);
  z-index: 1;
`

const ApplicationCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
`

const ModeSwitchWrapper = styled.div`
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  position: absolute;
`

const askCameraAccess = () => navigator.mediaDevices.getUserMedia({video: true})
  .then(stream => true)
  .catch(err => console.log(err) || false)

const getParentSize = element => ({
  width: element.parentNode.clientWidth,
  height: element.parentNode.clientHeight
})

const getWindowSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight
})

const ApplicationWrapper = ({ initialMode, cameraEnabled, youModeEnabled = true, goToCameraUsageAgreementScreen }) => {
  useScreenview('Experience')

  const visitor = useAnalytics()
  const [ allowCameraAccess, setCameraAllowState ] = useState(undefined)
  const [ mode, setMode ] = useState(initialMode || Mode.YOU)
  const [ instructionsVisible, setInstructionsVisible ] = useState(false)
  const [ modeSwitchStyle, setModeSwitchStyle ] = useState({})
  const canvasRef = useRef()
  const mainControlRef = useRef()
  const trackingRef = useRef()

  const changeMode = useCallback(() => {
    setMode(value => {
      const updatedValue = value === Mode.YOU ? Mode.THEM : Mode.YOU
      visitor.event('Mode Switch', `Toggle ${updatedValue.toUpperCase()}`).send()
      return updatedValue
    })
  }, [])

  const toggleTacking = useCallback(() => setTrackingEnabled(value => !value))

  useEffect(() => {
    const canvas = canvasRef.current
    const mainControl = new MainControl(canvas, {isMobile: isUsingMobileDevice()})
    mainControlRef.current = mainControl

    // Tracking and recording
    const tracking = createTracking(mainControl, {mode, cameraEnabled}, trackingSettings)
    trackingRef.current = tracking

    const subscription = tracking.instructionVisibility$.subscribe(setInstructionsVisible)

    return () => {
      mainControl.dispose()
      tracking.dispose()
      subscription.unsubscribe()
    }
  }, [])

  // Handle mode change
  useEffect(() => {
    const mainControl = mainControlRef.current
    const tracking = trackingRef.current

    if (mode === Mode.YOU && !cameraEnabled) {
      goToCameraUsageAgreementScreen()
    } else {
      mainControl.setMode(mode)
      tracking.setMode(mode)
    }
  }, [mode])

  // Handle window resize
  useEffect(() => {
    const resize$ = fromEvent(window, 'resize').pipe(
      debounceTime(100)
    )

    const subscription = resize$.pipe(
      startWith(null),
      map(() => getWindowSize())
    ).subscribe(({ width, height }) => {
      const mainControl = mainControlRef.current
      mainControl.setSize(width, height)

      // Get window bounds to position ModeSwitch
      const rect = mainControl.getYOUWindowProps()
      const style = isUsingMobileDevice()
        ? {top: rect.y + rect.height}
        : {bottom: height - (rect.y + rect.height)}
      setModeSwitchStyle(style)
    })

    return () => subscription.unsubscribe()
  }, [])

  return (
    <Fragment>
      <ApplicationCanvas ref={canvasRef} />

      <ModeSwitchWrapper style={modeSwitchStyle}>
        <ModeSwitch
          toggle={changeMode}
          value={mode !== Mode.YOU}
          offLabel={translate('you')}
          onLabel={translate('them')}
        />
      </ModeSwitchWrapper>

      <StyledInstructionText visible={instructionsVisible}>{translate('instructions')}</StyledInstructionText>
    </Fragment>
  )
}

export default () => {
  const visitor = useAnalytics()
  const [ error, setError ] = useState({})
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ infoVisible, setInfoVisible ] = useState(false)
  const [ canUseCamera, setCanUseCamera ] = useState(false)
  const [ initialMode, setInitialMode ] = useState(undefined)
  const [ youModeEnabled, setYouModeEnabled ] = useState(true)

  const displayInfo = useCallback(() => {
    setInfoVisible(true)
    visitor.event('Experience', 'Click Enter').send()
  }, [])

  const askCameraAccess = useCallback(() => {
    const constraints = {
      video: true,
      audio: false,
      facingMode: 'user'
    }

    visitor.event('Camera access agreement', 'Click Allow Webcam Access').send()

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        setCanUseCamera(true)
        setError({})
      })
      .catch(error => {
        visitor.exception(`Camera access error: [${error.name}] ${error.message}`).send()
        setError(error)
      })
  }, [])

  const showCameraUsageAgreementScreen = !canUseCamera && !initialMode
  const showApplication = canUseCamera || initialMode

  return (
    <Fragment>
      {showCameraUsageAgreementScreen && (
        <PageContainer>
          <LanguageSwitcher
            actionElement={<LinkButton onClick={displayInfo} children={translate('info')} />}
          />
          <CameraUsageAgreementScreen
            error={error}
            onAllow={askCameraAccess}
            onDeny={() => {
              visitor.event('Camera access agreement', 'Click No thanks').send()
              setInitialMode(Mode.THEM)
            }}
          />
        </PageContainer>
      )}

      {showApplication && (
        <>
          <LanguageSwitcherWrapper>
            <LanguageSwitcher
              inverted
              actionElement={<LinkButton inverted onClick={displayInfo} children={translate('info')} />}
            />
          </LanguageSwitcherWrapper>
          <ApplicationWrapper
            goToCameraUsageAgreementScreen={() => {
              setError({})
              setCanUseCamera(false)
              setYouModeEnabled(true)
              setInitialMode(undefined)
            }}
            cameraEnabled={canUseCamera}
            initialMode={initialMode}
            youModeEnabled={youModeEnabled}
          />
        </>
      )}

      {infoVisible && (<InfoScreen onClose={() => setInfoVisible(false)} />)}
    </Fragment>
  )
}
