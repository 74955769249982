import React, { useContext } from 'react'
import styled from 'styled-components'

import { LanguageContext } from './language'
import LinkButton from './ui/LinkButton'

const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledWrapper = styled.div`
  display: flex;
  padding: 2px;
  margin-top: -2px;
  margin-right: -2px;
  margin-bottom: -4px;

  ${({ inverted }) => inverted && `
    background: black;
    color: white;
  `}
`

const ExtraAction = styled(StyledWrapper)`
  padding-right: calc(var(--margin) / 2);
`

const StyledContainerElement = styled.a`
  cursor: pointer;
  background: transparent;

  :not(:first-child) {
    margin-left: 1em;
    ::before {
      content: "/";
      padding-right: 1em;
      text-decoration: none !important;
    }
  }
`

const StyledLanguageText = styled(LinkButton)`
  ${({ active }) => active && `
    border-color: transparent;
    cursor: default;
  `}
`

export default ({ actionElement, inverted = false }) => {
  const { language, setLanguage } = useContext(LanguageContext)

  return (
    <StyledContainer>
      {actionElement && (
        <ExtraAction inverted={inverted}>
          {actionElement}
        </ExtraAction>
      )}
      <StyledWrapper inverted={inverted}>
        <StyledContainerElement><StyledLanguageText inverted={inverted} active={language === 'en'} onClick={() => setLanguage('en')}>EN</StyledLanguageText></StyledContainerElement>
        <StyledContainerElement><StyledLanguageText inverted={inverted} active={language === 'fr'} onClick={() => setLanguage('fr')}>FR</StyledLanguageText></StyledContainerElement>
      </StyledWrapper>
    </StyledContainer>
  )
}
