import React from 'react'

export default {
  // Landing
  present: 'présentent',
  slogan: 'Créé en quarantaine par Daniel Iregui et le Studio Iregular',
  enter: 'Entrer',
  info: 'Info',

  // Info
  description: props => (
    <>
      <p>ANTICORPS est une expérience interactive sous forme d'appel vidéo sans fin. Les participants ont simplement besoin de se présenter sans rien dire / sans devoir rien dire. N'importe qui peut y participer en tout temps, et toutes les contributions sont acceptées.</p>
      <p>L'expérience utilise la webcam pour suivre le visage des participants et enregistrer leurs expressions faciales.</p>
      <p>Une commande de la Maison de Culture Ahuntsic à Montréal (Canada), ANTICORPS remplace une exposition de trois installations interactives du même artiste.</p>
    </>
  ),
  presentators: 'La Maison de la Culture Ahuntsic et Elektra',
  close: 'Fermer',
  and: 'et',
  createdBy: 'Créé par',
  coding: 'Programmation',
  visualAndSounds: 'Visuels et son',
  coordination: 'Coordination',
  curator: 'Commissaire',
  presentedBy: 'Pésenté par',
  madePossibleBy: 'Ce projet est soutenu par le programme de médiation culturelle des arrondissements montréalais dans le cadre de l\'entente sur le développement Culturel de Montréal.',

  // Camera usage agreement
  important: 'Important',
  accessAgreementText: 'Ceci est une œuvre interactive. Pour interagir, nous avons besoin de voir votre visage.',
  allowWebcamAccess: 'Autoriser l\'accès à la webcam',
  badBrowser: 'Le navigateur que vous utilisez bloque certaines fonctionnalités requises par cette expérience interactive. SVP utiliser un navigateur externe tels que Safari ou Chrome.',
  noThanks: 'Non merci',

  // Camera access
  cameraAbortError: `Erreur: un problème est survenu.`,
  cameraNotAllowedError: `L'Accès à la caméra n'est pas autorisé. SVP vérifez les permissions de votre navigateur.`,
  cameraNotFoundError: `Aucune caméra détectée, il vous est possible d'accéder à l'expérience en cliquant sur 'Non merci' mais vous serez limité à voir les autres participants.`,
  cameraNotReadableError: 'Problème matériel: la caméra est inaccessible.',
  cameraOverconstrainedError: 'Aucun appareil trouvé répondant aux critères',
  cameraSecurityError: `Erreur de sécurité: L'Accès à la caméra n'est pas autorisé. SVP vérifez les permissions de votre navigateur.`,

  // Experience (Chapter01)
  instructions: 'Révélez votre visage',
  you: 'Toi',
  them: 'Eux'
}
