import uniqid from 'uniqid'
import { EMPTY, BehaviorSubject, animationFrameScheduler, combineLatest, defer, from, fromEvent, iif, interval, of, merge, timer } from 'rxjs'
import { bufferCount, debounceTime, delay, distinctUntilChanged, filter, finalize, map, mapTo, mergeMap, partition, pluck, repeat, share, startWith, switchMap, switchMapTo, scan, take, takeUntil, tap, throttleTime, withLatestFrom } from 'rxjs/operators'
import { zip } from 'lodash'
import clmtrackr from 'clmtrackr'

import { Mode } from './constants'
import { apiSend } from './lib/api'
import { isUsingMobileDevice } from '../lib/detect'
import { acquireUserMedia, createVideo, createTrackerWrapper, hasValidLandmarks, fetchRandomUserFrames, sendUserFrames, resizeFrame } from './lib/utils'
import { track, singleCanvasCapture, videoReady } from './lib/helpers'

export const createTracking = (mainControl, props, settings) => {
  if (isUsingMobileDevice()) {
    console.log('%cMobile mode', 'color: hotpink; font-weight: bold;')
  } else {
    console.log('%cDesktop mode', 'color: dodgerBlue; font-weight: bold;')
  }

  // Create and append video to body
  const video = createVideo()
  document.body.appendChild(video)

  const userMediaConstraints = {
    video: true,
    audio: false,
    facingMode: 'user'
  }

  const acquireCamera = async () => {
    await acquireUserMedia(video, userMediaConstraints)
    return video
  }

  // View mode
  const mobileViewModeSubject = new BehaviorSubject(false)
  const mobileViewMode$ = mobileViewModeSubject.asObservable().pipe(
    distinctUntilChanged()
  )

  // YOU, THEM mode
  const modeSubject = new BehaviorSubject(props.mode)
  const mode$ = modeSubject.asObservable()

  const youMode$ = mode$.pipe(filter(mode => mode === Mode.YOU))
  const themMode$ = mode$.pipe(filter(mode => mode === Mode.THEM))

  const message = document.createElement('p')
  document.body.appendChild(message)
  message.setAttribute('style', `
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    text-align: center;
    color: hotpink;
    font-size: 84px;
  `)

  const applicationFocus$ = isUsingMobileDevice()
    ? fromEvent(window, 'focus').pipe(mapTo(true))
    : EMPTY

  const applicationBlur$ = isUsingMobileDevice()
    ? fromEvent(window, 'blur').pipe(mapTo(false))
    : EMPTY

  // Used to determine tracking state based on application focus
  const applicationFocusState$ = merge(applicationFocus$, applicationBlur$).pipe(
    startWith(true)
  )

  // XXX In case we only rely on mode change to determine tracing state
  // const trackingState$ = mode$.pipe(
  //   map(mode => mode === Mode.YOU)
  // )

  const trackingState$ = combineLatest(mode$, applicationFocusState$).pipe(
    map(([ mode, focused ]) => mode === Mode.YOU && focused)
  )

  const [ trackingEnabled$, trackingDisabled$ ] = trackingState$.pipe(
    distinctUntilChanged(),
    partition(enabled => enabled)
  )

  // XXX Special case to avoid prompting for camera access when
  // user has clicked 'No thanks'
  // Clearly not elegant but it works...
  const userFrames$ = props.cameraEnabled ? (
    defer(() => acquireCamera()).pipe(
      mergeMap(video => videoReady(video)),
      map(video => createTrackerWrapper(video)),
      mergeMap(tracker => trackingEnabled$.pipe(
        switchMap(() => track(tracker, settings).pipe(
          takeUntil(trackingDisabled$)
        )),
      )),
      share()
    )
  ) : EMPTY

  const validInteraction$ = userFrames$.pipe(
    filter(frame => frame.landmarks), // Ensure frame has landmarks
    bufferCount(10), // Amount to be considered valid
    take(1),
    mapTo(true)
  )

  const userFrameCapture$ = userFrames$.pipe(
    throttleTime(settings.captureThrottleTime),
    map(frame => resizeFrame(frame.image, frame.landmarks, settings.otherCaptureSize)),
    bufferCount(settings.captureAmount),
    filter(hasValidLandmarks(0.75)),
    share()
  )

  const themCapture$ = timer(0, settings.otherFetchInterval).pipe(
    mergeMap(() => fetchRandomUserFrames()),
  )

  // XXX Disable the mobile view change as it looks like a bug right now
  const mobileViewModeChange$ = of(false)

  const mobileViewModeInterval$ = interval(settings.mobileAlternateViewInterval).pipe(
    scan((acc, time) => !acc, false)
  )

  const youTiles$ = trackingEnabled$.pipe(
    switchMap(() => validInteraction$.pipe(
      switchMapTo(userFrameCapture$),
      takeUntil(trackingDisabled$)
    ))
  )

  const landmarksDetected$ = userFrames$.pipe(
    map(frame => frame.landmarks)
  )

  const noLandmarksForTooLong$ = youMode$.pipe(
    delay(settings.noDetectionTimeout),
    switchMap(() => landmarksDetected$.pipe(
        filter(landmarks => landmarks),
        startWith(null),
        switchMap(() => timer(settings.noDetectionTimeout).pipe(
          takeUntil(trackingDisabled$)
        )),
      )
    )
  )

  const themTiles$ = themMode$.pipe(
    switchMap(() => themCapture$.pipe(
      takeUntil(youMode$))
    )
  )

  const instructionVisibility$ = merge(
    noLandmarksForTooLong$.pipe(mapTo(true)),
    merge(
      themMode$,
      landmarksDetected$.pipe(filter(landmarks => landmarks))
    ).pipe(mapTo(false))
  ).pipe(
    distinctUntilChanged(),
  )

  const subscription = merge(
    // User frames
    userFrames$.pipe(
      tap(frame => {
        mainControl.setUserFrame(frame)
      })
    ),

    youTiles$.pipe(
      tap(frames => {
        // Set as loaded tile
        mainControl.addLoadedTile(frames)
        // Send to server
        sendUserFrames(frames)
      })
    ),

    themTiles$.pipe(
      tap(frames => {
        mainControl.addLoadedTile(frames)
      })
    ),

    trackingEnabled$.pipe(
      tap(() => {
        mainControl.onTrackingEnabled()
      })
    ),

    trackingDisabled$.pipe(
      tap(() => {
        mainControl.onTrackingDisabled()
      })
    ),

    mobileViewModeChange$.pipe(
      tap(flag => mobileViewModeSubject.next(flag))
    ),

    youMode$.pipe(
      tap(() => mobileViewModeSubject.next(false))
    ),

    noLandmarksForTooLong$.pipe(
      tap(() => {
        mainControl.onNoFaceDetected()
      })
    ),

    landmarksDetected$.pipe(
      filter(landmarks => landmarks),
      tap(() => {
        mainControl.onFaceDetected()
      })
    ),

    applicationFocus$.pipe(
      tap(() => {
          acquireUserMedia(video, userMediaConstraints)
      })
    ),

    applicationBlur$.pipe(
      tap(() => {
          const stream = video.srcObject
          const [ track ] = stream.getTracks()
          track.stop()
      })
    )
  ).subscribe()

  return {
    instructionVisibility$,

    setMode(mode) {
      modeSubject.next(mode)
    },

    dispose() {
      video.srcObject = null
      video.parentNode.removeChild(video)
      subscription.unsubscribe()
    }
  }
}

