import React from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  display: flex;
  background: white;
  padding: 4px 8px;
  align-items: center;
  cursor: pointer;
`

const Track = styled.div`
  background: white;
  width: 64px;
  height: 24px;
  padding: 2px;
  background: black;
  border-radius: 24px;
  margin: 0 12px;
`

const ThumbContainer = styled.div`
  position: relative;
  background: yellow;
`

const Thumb = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ color }) => color};
  border-radius: 20px;
  position: absolute;
  left: 0;
  transform: translateX(0);
  transition:
    left 200ms linear,
    transform 200ms linear,
    background 200ms linear;

  ${({ enabled }) => enabled && `
    left: 100%;
    transform: translateX(-100%);
  `}
`

const Label = styled.div`
  width: 6ch;
  text-align: center;
  text-transform: uppercase;
  transition: color 200ms linear;

  ${({ enabled, color }) => enabled && `
    color: ${color};
  `}
`

const YOU_COLOR = '#0000ff'
const THEM_COLOR = '#ff0000'

export default ({ value, toggle, offLabel = 'on', onLabel = 'off', ...props }) => (
  <Container enabled={value} onClick={toggle} {...props}>
    <Label color={YOU_COLOR} enabled={!value}>{offLabel}</Label>
    <Track>
      <ThumbContainer>
        <Thumb
          enabled={value}
          color={value ? THEM_COLOR : YOU_COLOR}
        />
      </ThumbContainer>
    </Track>
    <Label color={THEM_COLOR} enabled={value}>{onLabel}</Label>
  </Container>
)
