import styled from 'styled-components'

export default styled.button`
  padding: 8px 34px;
  padding-left: 38px;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  border: 1px solid black;
  background: white;
  transition:
    background 200ms,
    color 100ms;

  :hover {
    background: black;
    color: white;
  }

  ${({ solid }) => solid && `
    background: black;
    color: white;

    :hover {
      background: white;
      color: black;
    }
  `}
`
