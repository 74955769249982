import React from 'react'
import styled from 'styled-components'

import { useAnalytics, useScreenview } from './analytics'
import { translate } from './language'
import BlinkingLogo from './ui/BlinkingLogo'
import LinkButton from './ui/LinkButton'
import LanguageSwitcher from './LanguageSwitcher'
import { PrimaryButtonArea, SecondaryButtonArea } from './layouts'
import PageContainer from './ui/PageContainer'
import Header from './ui/Header'
import montrealLogo from '../assets/montreal-logo.svg'
import quebecGovernmentLogo from '../assets/quebec-government-logo.svg'
import maisonCultureAhuntsic from '../assets/maison-culture-ahuntsic-logo.svg'
import mediationCulturelleLogo from '../assets/mediation-culturelle-logo.png'
import elektraLogo from '../assets/elektra-logo.svg'
import arsenalLogo from '../assets/arsenal-logo.svg'

const columnsLayoutThreshold = 'max-width: 1024px'

const PartnerLogosContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: calc(2 * var(--margin)) 0;

  img {
    width: 10vw;

    :not(:first-child) {
      margin-left: var(--margin);
    }
  }

  @media (${columnsLayoutThreshold}) {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    grid-gap: var(--margin);

    img {
      margin-left: 0;
      width: auto;
      max-width: calc(4 * var(--margin));

      :not(:first-child) {
        margin-left: 0;
      }

      :nth-child(odd) {
        justify-self: end;
      }
    }
  }
`

const StyledContainer = styled(PageContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  text-align: left;
  overflow-y: auto;
  z-index: 3;
`

const StyledTextBlockContainer = styled.div`
  display: flex;
  text-transform: uppercase;
  max-width: calc(20 * var(--margin));
  margin: 0 auto;

  @media (${columnsLayoutThreshold}) {
    flex-direction: column;
  }
`

const StyledTextBlock = styled.div`
  line-height: 1.5;
  flex: 1;

  :not(:first-child) {
    margin-left: calc(2 * var(--margin));
  }

  @media (${columnsLayoutThreshold}) {
    :not(:first-child) {
      margin: var(--margin) 0;
    }
  }
`

const StyledDescriptiveText = styled(StyledTextBlock)`
  p + p {
    margin-top: 1em;
  }
`

const StyledLogoContainer = styled.div`
  width: 50%;
  margin: var(--margin) auto;

  @media (${columnsLayoutThreshold}) {
    width: 80%;
  }
`

const StyledSlogan = styled.div`
  margin-top: calc(0.5 * var(--margin));
  text-align: center;
  text-transform: uppercase;
`

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export default ({ onClose }) => {
  const visitor = useAnalytics()
  useScreenview('Info')

  return (
    <StyledContainer>
      <LanguageSwitcher actionElement={<LinkButton onClick={onClose}>{translate('close')}</LinkButton>} />

      <Header />

      <StyledLogoContainer>
        <BlinkingLogo />
        <StyledSlogan>{translate('slogan')}</StyledSlogan>
      </StyledLogoContainer>

      <StyledTextBlockContainer>
        <StyledDescriptiveText>
          {translate('description')}
        </StyledDescriptiveText>

        <StyledTextBlock>
          <p>{translate('createdBy')} — Daniel Iregui {translate('and')} Studio Iregular</p>
          <p>{translate('coding')} — Guillaume Turgeon {translate('and')} Hugo St-Onge</p>
          <p>{translate('visualAndSounds')} — Daniel Iregui</p>
          <p>{translate('coordination')} — Marilyne Lacombe</p>
          <br />
          <p>{translate('curator')} — Liette Gauthier</p>
          <p>{translate('presentedBy')} — Maison de la Culture Ahuntsic</p>
          <br />
          <p>{translate('madePossibleBy')}</p>
        </StyledTextBlock>
      </StyledTextBlockContainer>

      <PartnerLogosContainer>
        <img src={montrealLogo} />
        <img src={quebecGovernmentLogo} />
        <img src={maisonCultureAhuntsic} />
        <img src={mediationCulturelleLogo} />
        <img src={elektraLogo} />
        <img src={arsenalLogo} />
      </PartnerLogosContainer>

      <CloseButtonWrapper>
        <LinkButton onClick={onClose}>{translate('close')}</LinkButton>
      </CloseButtonWrapper>
    </StyledContainer>
  )
}
