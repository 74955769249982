import React, { useEffect, useRef } from 'react'

import textBlink from '../../lib/text-blink'
import VectorLogo from './VectorLogo'

export default () => {
  const vectorLogoRef = useRef()

  useEffect(() => {
    const vectorLogo = vectorLogoRef.current
    const letters = vectorLogo.querySelectorAll('path')
    const elements = Array.prototype.map.call(letters, element => color => element.setAttribute('fill', color))
    return textBlink(elements, ['#ff0000', '#00ffff', '#0000ff', '#000000'])
  }, [vectorLogoRef.current])

  return <VectorLogo ref={vectorLogoRef} />
}

