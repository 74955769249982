import React, { createElement, createContext, useContext, useEffect, useState } from 'react'
import ua from 'universal-analytics'

export const AnalyticsContext = createContext()

export const useAnalytics = () => {
  const visitor = useContext(AnalyticsContext)
  return visitor
}

export const useScreenview = screenName => {
  const visitor = useAnalytics()

  useEffect(() => {
    visitor.screenview(screenName, window.location.origin).send()
  }, [])
}

export const AnalyticsProvider = ({ code, children }) => {
  const [ visitor ] = useState(() => ua(code)) // Shouldn't be instantiated more than once
  return <AnalyticsContext.Provider value={visitor} children={children} />
}
