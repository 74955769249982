import uniqid from 'uniqid'
import { EMPTY, BehaviorSubject, animationFrameScheduler, combineLatest, from, fromEvent, interval, merge, timer } from 'rxjs'
import { bufferCount, debounceTime, distinctUntilChanged, filter, finalize, map, mapTo, mergeMap, pluck, repeat, share, switchMap, switchMapTo, take, tap, throttleTime, withLatestFrom } from 'rxjs/operators'
import { zip } from 'lodash'
import clmtrackr from 'clmtrackr'

import { apiSend, apiFetchRandom } from './api'

const sessionId = uniqid()

export const resizeFrame = (source, landmarks, size) => {
  const canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size
  const ctx = canvas.getContext('2d')

  const coverDimension = Math.min(source.width, source.height)
  const scale = size / coverDimension

  const offset = {
    x: -0.5 * (source.width - coverDimension),
    y: -0.5 * (source.height - coverDimension)
  }

  ctx.drawImage(
    source,
    scale * offset.x,
    scale * offset.y,
    source.width * scale,
    source.height * scale
  )

  // Transform landmarks to fit adjusted size
  const transformedLandmarks = landmarks && landmarks.map(([ x, y ]) => ([
    (x + offset.x) * scale,
    (y + offset.y) * scale
  ]))

  return {
    image: canvas,
    landmarks: transformedLandmarks
  }
}

export const captureFrame = (source, landmarks, size) => {
  const canvas = document.createElement('canvas')
  canvas.width = size
  canvas.height = size
  const ctx = canvas.getContext('2d')

  const coverDimension = Math.min(source.videoWidth, source.videoHeight)
  const scale = size / coverDimension

  const offset = {
    x: -0.5 * (source.videoWidth - coverDimension),
    y: -0.5 * (source.videoHeight - coverDimension)
  }

  ctx.drawImage(
    source,
    scale * offset.x,
    scale * offset.y,
    source.videoWidth * scale,
    source.videoHeight * scale
  )

  // Transform landmarks to fit adjusted size
  const transformedLandmarks = landmarks && landmarks.map(([ x, y ]) => ([
    (x + offset.x) * scale,
    (y + offset.y) * scale
  ]))

  return {
    image: canvas,
    landmarks: transformedLandmarks
  }
}

export const createSpritesheet = frames => {
  const spritesheet = document.createElement('canvas')
  const ctx = spritesheet.getContext('2d')

  spritesheet.width = frames.length * frames[0].width
  spritesheet.height = frames[0].height
  frames.forEach((image, i) => ctx.drawImage(image, i * image.width, 0))
  return spritesheet
}

// Assume the spritesheet is just a strip on the X axis
const splitSpritesheet = (spritesheet, count) => {
  const width = spritesheet.width / count
  const height = spritesheet.height

  const images = Array.from(Array(count))
    .map(() => document.createElement('canvas'))

  images.forEach((canvas, i) => {
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    // img, sx, sy, swidth, sheight, dx, dy, dwidth, dheight
    ctx.drawImage(spritesheet, i * width, 0, width, height, 0, 0, width, height)
  })

  return images
}

const loadImage = source => new Promise((resolve, reject) => {
  const img = document.createElement('img')
  img.src = source

  const handler = event => {
    img.removeEventListener('load', handler)
    resolve(img)
  }

  img.addEventListener('load', handler)
})

export const fetchRandomUserFrames = async () => {
  const response = await apiFetchRandom(sessionId)
  const spritesheet = await loadImage(response.data.file)
  const landmarks = response.data.data
  const images = splitSpritesheet(spritesheet, landmarks.length)
  return zip(landmarks, images).map(([ landmarks, image ]) => ({landmarks, image}))
}

// Determine validity of capture
export const hasValidLandmarks = ratio => frames => {
  const validFrames = frames.filter(({ landmarks }) => landmarks)
  return validFrames.length >= frames.length * ratio
}

export const createVideo = () => {
  const video = document.createElement('video')
  video.autoplay = true
  video.muted = true
  video.playsInline = true
  video.style = `
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
  `

  return video
}

export const acquireUserMedia = (video, constraints) => {
  return navigator.mediaDevices.getUserMedia(constraints)
    .then(stream => video.srcObject = stream)
    .catch(() => {})
}

export const createTrackerWrapper = video => {
  const tracker = new clmtrackr.tracker()
  video.width = video.videoWidth
  video.height = video.videoHeight
  video.play()

  tracker.init()

  return {
    video: video,
    start: () => tracker.start(video),
    stop: () => tracker.stop(),
    getCurrentPosition: () => tracker.getCurrentPosition()
  }
}

export const sendUserFrames = (() => {
  const sessionId = uniqid()

  return frames => {
    const spritesheet = createSpritesheet(frames.map(({ image }) => image))
    const data = frames.map(({ landmarks }) => landmarks)
    spritesheet.toBlob(blob => apiSend(sessionId, blob, data), 'image/jpeg')
  }
})()
