import React from 'react'
import styled from 'styled-components'

import { translate } from '../language'

const StyledHeader = styled.section`
  margin-top: var(--margin);
  text-align: center;
  text-transform: uppercase;
  transition: color 300ms linear;
`

const SecondLine = styled.p`
  margin-top: 0.5em;
`

export default () => (
  <StyledHeader>
    {translate('presentators')}
    <SecondLine>{translate('present')}</SecondLine>
  </StyledHeader>
)

