export default class TileScroller {

    constructor() {
        this._rows = 1;
        this._cols = 1;

        this._tileW = 0;
        this._tileH = 0;

        this._canvasW = 0;
        this._canvasH = 0;

        this._allTiles = [];

        this._allSpeedsX = [];
        this._allSpeedsY = [];

        this._minScrollSpeed = .1;
        this._maxScrollSpeed = 2;

        this._isScrollingH = false;
        
        this._width = 0;
        this._height = 0;
    }

    setProperties(cols, rows, canvasWidth, canvasHeight) {

        this._rows = rows;
        this._cols = cols;

//        this._tileW = Math.ceil(canvasWidth / cols);
//        this._tileH = Math.ceil(canvasHeight / rows);
        
        this._tileW = canvasWidth / cols;
        this._tileH = canvasHeight / rows;

        this._canvasW = canvasWidth;
        this._canvasH = canvasHeight;
        
        this._width = this._cols * this._tileW;
        this._height = this._rows * this._tileH;

        this._allTiles = [];

        for (let i = 0; i < rows + 1; i++) {
            for (let j = 0; j < cols + 1; j++) {
                let tile = {};
                tile.initX = this._tileW * j;
                tile.initY = this._tileH * i;
                tile.id = this._allTiles.length;
                tile.initColIndex = j;
                tile.initRowIndex = i;
                tile.isMirrorX = 1;
                
                this._allTiles.push(tile);                
                this.changeTileBGIndex(tile.id);
                
                //console.log('bgIndex',tile.bgIndex);
            }
        }
        
        this.resetPositions();
        this.clampMaxSpeedToTileDimension();
        this.setRandomSpeeds();
        this.changeMirrorMode();
    }
    changeMirrorMode(){
        /////////////mirror modes
        let mirrorModes = ['same','cols','rows','random','halfV','halfH'];
        let mirrorMode = mirrorModes[Math.floor(Math.random()*mirrorModes.length)];
        let isFirstMirror = Math.random() < .5;
        let currentMirror = isFirstMirror;
        /////////////
        for (let tile of this._allTiles) {
            switch(mirrorMode){
                case "same":
                    ///do nothing
                    break;
                case "cols":
                    currentMirror = tile.initColIndex%2 == 0 ? isFirstMirror : !isFirstMirror;
                    break;
                case "rows":
                    currentMirror = tile.initRowIndex%2 == 0 ? isFirstMirror : !isFirstMirror;
                    break;
                case "random":
                    currentMirror = Math.random()<.5;
                    break;
                case "halfV":
                    currentMirror = tile.initColIndex < (this._cols-1)/2 ? isFirstMirror : !isFirstMirror;
                    break;
                case "halfH":
                    currentMirror = tile.initRowIndex < (this._rows-1)/2 ? isFirstMirror : !isFirstMirror;
                    break;
            }
            tile.isMirrorX = currentMirror;
            //tile.isMirrorY = Math.random()<1.5;
        }
    }
    changeAllTilesBGIndex(){        
        for (let tile of this._allTiles) {
            this.changeTileBGIndex(tile.id);
        }
    }
    changeTileBGIndex(index){
        
        let indexes = [0,1,2,3];    
        
        if(this._allTiles[index]){
            this._allTiles[index].bgIndex = indexes[Math.floor(Math.random() * indexes.length)];
        }
    }
    resetPositions() {
        for (let tile of this._allTiles) {
            tile.x = tile.initX;
            tile.y = tile.initY;
        }
    }
    getTiles() {
        return this._allTiles.slice();
    }
    getTileWidth() {
        return this._tileW;
    }
    getTileHeight() {
        return this._tileH;
    }
    getWidth() {
        return this._width;
    }
    getHeight() {
        return this._height;
    }
    setRandomSpeeds() {
        this._allSpeedsX = [];
        for (let i = 0; i < this._rows + 1; i++) {
            this._allSpeedsX.push(this.getRandomSpeed());
        }
        this._allSpeedsY = [];
        for (let i = 0; i < this._cols + 1; i++) {
            this._allSpeedsY.push(this.getRandomSpeed());
        }
    }
    getRandomSpeed() {
        let speed = this._minScrollSpeed + (this._maxScrollSpeed - this._minScrollSpeed) * Math.random();
        speed = Math.random() < .5 ? speed * -1 : speed;
        return speed;
    }
    clampMaxSpeedToTileDimension() {
        this._maxScrollSpeed = this._isScrollingH ? this._tileW : this._tileH;
        this._maxScrollSpeed *= 1 / 16;
    }
    setRandomScrollAxis() {
        this._isScrollingH = Math.random() < .5;
    }
    setScrollAxis(isHorizontal) {
        this._isScrollingH = isHorizontal;
    }
    randomize() {
        this.setRandomSpeeds();
        this.setRandomScrollAxis();
    }
    scroll() {
        for (let tile of this._allTiles) {

            let speedX = this._allSpeedsX[tile.initRowIndex];
            let speedY = this._allSpeedsY[tile.initColIndex];

            tile.x = this._isScrollingH ? tile.x + speedX : tile.initX;
            tile.y = this._isScrollingH ? tile.initY : tile.y + speedY;

            if (speedX > 0 && tile.x > this._canvasW) {
                tile.x = -(this._tileW - (tile.x - this._canvasW));
            }
            if (speedX < 0 && tile.x < -this._tileW) {
                tile.x = this._canvasW - (Math.abs(tile.x) - this._tileW);
            }

            if (speedY > 0 && tile.y > this._canvasH) {
                tile.y = -(this._tileH - (tile.y - this._canvasH));
            }
            if (speedY < 0 && tile.y < -this._tileH) {
                tile.y = this._canvasH - (Math.abs(tile.y) - this._tileH);
            }
        }
    }
}
