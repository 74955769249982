import React, { useMemo, useRef, useEffect } from 'react'
import styled from 'styled-components'

import { translate } from './language'
import { useAnalytics, useScreenview } from './analytics'
import textBlink from '../lib/text-blink'
import PageContainer from './ui/PageContainer'
import ActionButton from './ui/ActionButton'
import LinkButton from './ui/LinkButton'
import { isFacebookApp } from '../lib/detect'

const StyledContainer = styled.div`
  overflow-y: auto;
`

const StyledTextContainer = styled.div`
  text-align: center;
`

const AgreementText = styled.p`
  line-height: 1.5;
  margin: calc(2 * var(--margin)) auto 0;

  @media (min-width: 1024px) {
    max-width: calc(9 * var(--margin));
  }
`

const StyledError = styled(AgreementText)`
  color: red;
`

const StyledActionButton = styled(ActionButton)`
  margin-top: calc(2 * var(--margin));
  margin-bottom: var(--margin);
`

const WebsiteLink = styled.a`
  display: block;
  color: blue;
  text-transform: uppercase;
  text-decoration: underline;
  margin-top: 5.5vh;
`

const StyledImportantText = styled.p`
  margin-top: var(--margin);
  text-transform: uppercase;
  background: cyan;
  display: inline-block;
  padding: 2px 1em;
`

const palette = [
  {background: 'red', color: 'black'},
  {background: 'cyan', color: 'black'},
  {background: 'blue', color: 'white'},
  {background: 'black', color: 'white'},
]

const ImportantText = ({ children }) => {
  const textRef = useRef()

  useEffect(() => {
    const update = ({ background, color }) => {
      textRef.current.style.background = background
      textRef.current.style.color = color
    }

    return textBlink([update], palette)
  }, [textRef.current])

  return <StyledImportantText ref={textRef} children={children} />
}

// =============================================================================
// Error handling
// =============================================================================
// AbortError: Although the user and operating system both granted access to the hardware device, and no hardware issues occurred that would cause a NotReadableError, some problem occurred which prevented the device from being used.
// NotAllowedError: cannot access camera
// NotFoundError: No device can be found
// NotReadableError: Although the user granted permission to use the matching devices, a hardware error occurred at the operating system, browser, or Web page level which prevented access to the device.
// OverconstrainedError: The specified constraints resulted in no candidate devices which met the criteria requested. The error is an object of type OverconstrainedError, and has a constraint property whose string value is the name of a constraint which was impossible to meet, and a message property containing a human-readable string explaining the problem.
// SecurityError: User media support is disabled on the Document on which getUserMedia() was called. The mechanism by which user media support is enabled and disabled is left up to the individual user agent.
// TypeError: The list of constraints specified is empty, or has all constraints set to false. This can also happen if you try to call getUserMedia() in an insecure context, since navigator.mediaDevices is undefined in an insecure context.
// =============================================================================
// {error && error.name === 'AbortError' && <StyledError>Permission aborted: Some problem occured.</StyledError>}
// {error && error.name === 'NotFoundError' && <StyledError>No device was found... You can still access the experience by clicking "No thanks" but you will be limited to seing others.</StyledError>}
// {error && error.name === 'NotReadableError' && <StyledError>Hardware error occured, camera is not readable.</StyledError>}
// {error && error.name === 'OverconstrainedError' && <StyledError>No device that matched the given constraints was found. ({error.message})</StyledError>}
// {error && error.name === 'SecurityError' && <StyledError>Security error: User media support is disabled</StyledError>}
// {error && error.name === 'TypeError' && <StyledError>Type error...</StyledError>}

export default ({ onAllow, onDeny, error = {} }) => {
  const visitor = useAnalytics()
  const canUseCamera = useMemo(() => !isFacebookApp() && navigator.mediaDevices, [])

  useScreenview('Camera access agreement')

  return (
    <StyledContainer>
      <StyledTextContainer>
        <ImportantText>{translate('important')}</ImportantText>

        {(canUseCamera && !error.name) && <AgreementText>{translate('accessAgreementText')}</AgreementText>}

        {!canUseCamera && (
          <>
            <AgreementText>{translate('badBrowser')}</AgreementText>
            <WebsiteLink href={window.location.origin} children={window.location.origin} />
          </>
        )}

        {error && error.name === 'AbortError' && <StyledError>{translate('cameraAbortError')}</StyledError>}
        {error && error.name === 'NotAllowedError' && <StyledError>{translate('cameraNotAllowedError')}</StyledError>}
        {error && error.name === 'NotFoundError' && <StyledError>{translate('cameraNotFoundError')}</StyledError>}
        {error && error.name === 'NotReadableError' && <StyledError>{translate('cameraNotReadableError')}</StyledError>}
        {error && error.name === 'OverconstrainedError' && <StyledError>{translate('cameraOverconstrainedError')}</StyledError>}
        {error && error.name === 'SecurityError' && <StyledError>{translate('cameraSecurityError')}</StyledError>}
      </StyledTextContainer>

      {canUseCamera && (
        <>
          <StyledActionButton onClick={onAllow}>{translate('allowWebcamAccess')}</StyledActionButton>
          <div><LinkButton onClick={onDeny}>{translate('noThanks')}</LinkButton></div>
        </>
      )}
    </StyledContainer>
  )
}
