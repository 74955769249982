import axios from 'axios'

import { getApiHmac } from '../../lib/hmac'

export const apiSend = (sessionId, spritesheet, data) => {
    const formData = new FormData()
    formData.set('data', JSON.stringify(data))
    formData.set('spritesheet', spritesheet)

    const url = `/api/chapter-1/send/${sessionId}`
    const hmac = getApiHmac('POST', url)

    return axios.post(url, formData, { headers: { Authentication: hmac } })
}

export const apiFetch = (counter = undefined) => {
    let url = '/api/chapter-1/fetch'
    if (typeof counter !== 'undefined') {
        url += `/${counter}`
    }

    const hmac = getApiHmac('GET', url)

    return axios.get(url, { headers: { Authentication: hmac } })
}

export const apiFetchRandom = (sessionId = undefined) => {
    let url = '/api/chapter-1/fetch-random'
    if (typeof sessionId !== 'undefined') {
        url += `/${sessionId}`
    }

    const hmac = getApiHmac('GET', url)

    return axios.get(url, { headers: { Authentication: hmac } })
}

export const apiFetchSession = (sessionId) => {
    const url = `/api/chapter-1/fetch-session/${sessionId}`
    const hmac = getApiHmac('GET', url)

    return axios.get(url, { headers: { Authentication: hmac } })
}
