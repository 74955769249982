import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link, useParams } from 'react-router-dom'

import Chapter01 from './Chapter01'

const StyledContainer = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: #222;
`

const StyledChapterButton = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 8px;
  background: #555;

  ${({ active }) => active && `
    background-color: #fefefe;
  `}
`

const StyledChapterNavigation = styled.div`
  display: flex;
`

const StyledContent = styled.div`
  flex: 1;
  overflow: hidden;
`

const BackLink = styled(Link)`
  color: white;
  margin-left: 200px;
`

export default () => {
  const { chapter } = useParams()
  return (
    <StyledContainer>
      <StyledContent>
        {chapter === '01' && <Chapter01 />}
      </StyledContent>
    </StyledContainer>
  )
}
