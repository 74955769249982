import React from 'react'

export default {
  // Landing
  present: 'present',
  slogan: 'Created in quarantine by Daniel Iregui and Studio Iregular',
  enter: 'Enter',
  info: 'Info',

  // Info
  description: props => (
    <>
      <p>ANTIBODIES is an interactive experience in the form of a never-ending video call. Participants only show up and do not have to say anything. Anyone can join at any time and all contributions are accepted.</p>
      <p>The experience uses the webcam to track the face of participants and record their face gestures.</p>
      <p>It was commissioned by la Maison de la Culture Ahuntsic, in Montréal (Canada), to replace an exhibition of 3 interactive installations by the same artist.</p>
    </>
  ),
  presentators: 'La Maison de la Culture Ahuntsic and Elektra',
  close: 'Close',
  and: 'and',
  createdBy: 'Created by',
  coding: 'Coding',
  visualAndSounds: 'Visuals and sounds',
  coordination: 'Coordination',
  curator: 'Curator',
  presentedBy: 'Presented by',
  madePossibleBy: 'This project is supported by the Montreal boroughs cultural outreach program as part of the "Entente sur le développement culturel de Montréal"',

  // Camera usage agreement
  important: 'Important',
  accessAgreementText: 'This is an interactive artwork. If you want to interact we need to see your face.',
  allowWebcamAccess: 'Allow webcam access',
  badBrowser: 'The browser you are using does not support the interactive features of this experience. Please open the link in a browser like Safari or Chrome.',
  noThanks: 'No thanks',

  // Camera access
  cameraAbortError: 'Permission aborted: Some problem occured.',
  cameraNotAllowedError: 'Not allowed to get camera access. Please verify browser permission.',
  cameraNotFoundError: 'No device was found. You can still access the experience by clicking "No thanks" but you will be limited to seing other participants.',
  cameraNotReadableError: 'Hardware error occured, camera is not readable.',
  cameraOverconstrainedError: 'No device that matched the given constraints was found.',
  cameraSecurityError: 'Security error: Not allowed to get camera access. Please verify browser permission.',

  // Experience (Chapter01)
  instructions: 'Show us your face',
  you: 'You',
  them: 'Them'
}


