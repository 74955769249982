import styled from 'styled-components'

export const PrimaryButtonArea = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 18vh;
  padding: 0 5w;
`

export const SecondaryButtonArea = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6vh;
  display: flex;
`
