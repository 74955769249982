const randomCandidates = candidates => () => candidates[Math.floor(Math.random() * candidates.length)]

export default (elements, colors) => {
  let frameId

  const randomColor = randomCandidates(colors)

  // Each element is a letter
  const loop = () => {
    for (const element of elements) {
      (Math.random() > 0.5) && element(randomColor())
    }

    frameId = window.requestAnimationFrame(loop)
  }

  loop()

  // Cleanup
  return () => {
    window.cancelAnimationFrame(frameId)
  }
}
