import { hot } from 'react-hot-loader'
import React, { Fragment, useCallback, useState } from 'react'
import { MemoryRouter, Route, Switch } from 'react-router-dom'

import GlobalStyle from './GlobalStyle'
import Landing from './Landing'
import { LanguageProvider } from './language'
import { AnalyticsProvider } from './analytics'
import ChapterContainer from './ChapterContainer'

const Main = () => {
  return (
    <Fragment>
      <AnalyticsProvider code={window.__gaCode}>
        <LanguageProvider>
        <GlobalStyle />

        <MemoryRouter>
          <Route exact path={'/'}><Landing /></Route>
          <Route path={'/chapter/:chapter'}><ChapterContainer /></Route>
        </MemoryRouter>
        </LanguageProvider>
      </AnalyticsProvider>
    </Fragment>
  )
}

export default hot(module)(Main)
