import React, { Fragment, useContext, forwardRef } from 'react'
import { LanguageContext } from '../language'

const LogoEn = forwardRef((props, ref) => (
  <svg ref={ref} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1678.79 90">
    <path d="M33.36,2.16H45L78.24,87.84h-12L57.24,64H20.52l-9,23.88H0Zm20.52,52.2L38.76,14.64,23.88,54.36Z"/>
    <path d="M198.72,2.16h10.92l43.56,68v-68h10.68V87.84H253.32L209.4,20.16V87.84H198.72Z"/>
    <path d="M413.52,12H385.2V2.16H453V12H424.68V87.84H413.52Z"/>
    <path d="M574.32,2.16h11V87.84h-11Z"/>
    <path d="M714,2.16h28.44a54,54,0,0,1,15,1.74,25,25,0,0,1,9.3,4.68,15.42,15.42,0,0,1,4.74,7,27.33,27.33,0,0,1,1.32,8.58,20.75,20.75,0,0,1-2.7,10.56q-2.7,4.68-9.42,7.8a27.77,27.77,0,0,1,7.14,3.9,20.24,20.24,0,0,1,4.56,4.92,17.4,17.4,0,0,1,2.4,5.64,27.58,27.58,0,0,1,.66,6.06,28.67,28.67,0,0,1-1.62,9.78,19.81,19.81,0,0,1-5.22,7.86,25.08,25.08,0,0,1-9.48,5.28,47.3,47.3,0,0,1-14.52,1.92H714ZM743.88,38.4q9.72,0,13.92-3.54T762,24.48q0-7-4.5-10.08t-15.42-3.12H724.8V38.4Zm.36,40.32A37.33,37.33,0,0,0,754,77.64a16.26,16.26,0,0,0,6.24-3.06,10.29,10.29,0,0,0,3.24-4.8,21.1,21.1,0,0,0,.9-6.42q0-7.56-4.44-11.58t-14.88-4H724.8v31Z"/>
    <path d="M936,90a42.3,42.3,0,0,1-16-2.94,34,34,0,0,1-12.48-8.64,39.56,39.56,0,0,1-8.1-14.1,59.07,59.07,0,0,1-2.88-19.2,58,58,0,0,1,2.94-19.2,40.91,40.91,0,0,1,8.16-14.16A34.31,34.31,0,0,1,920.1,3,41.23,41.23,0,0,1,936,0a42.3,42.3,0,0,1,16,2.94,33.46,33.46,0,0,1,12.42,8.64,40.14,40.14,0,0,1,8,14,58.53,58.53,0,0,1,2.88,19.14A60.79,60.79,0,0,1,972.54,64a39.91,39.91,0,0,1-8,14.22A33.76,33.76,0,0,1,952.14,87,41.88,41.88,0,0,1,936,90Zm0-9.6a27.42,27.42,0,0,0,12.06-2.52,23.56,23.56,0,0,0,8.7-7.14A32.81,32.81,0,0,0,962,59.58,54.91,54.91,0,0,0,963.84,45,54.42,54.42,0,0,0,962,30.54a32.82,32.82,0,0,0-5.34-11.22,24,24,0,0,0-8.7-7.2A27,27,0,0,0,936,9.6a27.72,27.72,0,0,0-12.18,2.52,23.84,23.84,0,0,0-8.76,7.2,32,32,0,0,0-5.28,11.22A56.19,56.19,0,0,0,908,45a56.71,56.71,0,0,0,1.74,14.58A32,32,0,0,0,915,70.74a23.56,23.56,0,0,0,8.7,7.14A28,28,0,0,0,936,80.4Z"/>
    <path d="M1101,2.16h30a50.55,50.55,0,0,1,16.74,2.58,31.51,31.51,0,0,1,12.48,7.86,35.49,35.49,0,0,1,7.86,13.2,56.57,56.57,0,0,1,2.76,18.6,55.85,55.85,0,0,1-2.94,19,35.42,35.42,0,0,1-8.46,13.56A35.72,35.72,0,0,1,1146,85.08a53.73,53.73,0,0,1-17.76,2.76H1101Zm27.72,76.08q16,0,23.28-8.94t7.32-24.66a54.53,54.53,0,0,0-1.62-13.92,27.64,27.64,0,0,0-5-10.38,21.61,21.61,0,0,0-8.82-6.48A33.68,33.68,0,0,0,1131,11.64h-19v66.6Z"/>
    <path d="M1296.35,2.16h11.05V87.84h-11.05Z"/>
    <path d="M1436,2.16h55.44v9.72h-44.4V38.76h42V48.6h-42V78.12h46.68l-1.8,9.72H1436Z"/>
    <path d="M1623.23,63.48q1.44,9,7.14,13t17,4a30.15,30.15,0,0,0,9.42-1.26,16.53,16.53,0,0,0,6.06-3.36,11.77,11.77,0,0,0,3.24-4.92,18.31,18.31,0,0,0,1-5.94,19.49,19.49,0,0,0-.6-4.86,10,10,0,0,0-2.34-4.26,15,15,0,0,0-4.8-3.36,31.43,31.43,0,0,0-7.86-2.28l-10.8-1.92a64.26,64.26,0,0,1-11.28-2.88,28.22,28.22,0,0,1-8.34-4.62,17.75,17.75,0,0,1-5.16-7,26.65,26.65,0,0,1-1.74-10.2,21,21,0,0,1,2.46-10.32,22.37,22.37,0,0,1,6.66-7.44,29.08,29.08,0,0,1,10-4.44A51.37,51.37,0,0,1,1645.67,0a46.44,46.44,0,0,1,14.22,1.86,26.64,26.64,0,0,1,9.3,5,21.18,21.18,0,0,1,5.46,7.14,41.62,41.62,0,0,1,2.82,8.22l-10.32,2a30.22,30.22,0,0,0-2.46-6.42,14.06,14.06,0,0,0-4-4.68,17.35,17.35,0,0,0-6.06-2.82,34.45,34.45,0,0,0-8.76-1,38,38,0,0,0-9.84,1.08,18.08,18.08,0,0,0-6.36,2.94,10.43,10.43,0,0,0-3.42,4.44,14.71,14.71,0,0,0-1,5.46,17.28,17.28,0,0,0,.78,5.46,9.73,9.73,0,0,0,2.64,4.08,14.63,14.63,0,0,0,4.86,2.88,44.64,44.64,0,0,0,7.44,2l12,2.16q13.32,2.52,19.56,8.34t6.24,17.1a23.72,23.72,0,0,1-2,9.66,22,22,0,0,1-5.88,7.86,28.19,28.19,0,0,1-9.78,5.28,44.57,44.57,0,0,1-13.8,1.92,58.31,58.31,0,0,1-13.74-1.5,30.93,30.93,0,0,1-10.74-4.74,24.35,24.35,0,0,1-7.32-8.28,30.14,30.14,0,0,1-3.36-12Z"/>
  </svg>
))

const LogoFr = forwardRef((props, ref) => (
  <svg ref={ref} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1678.79 90">
    <path d="M33.36,2.16H45L78.24,87.84h-12L57.24,64H20.52l-9,23.88H0Zm20.52,52.2L38.76,14.64,23.88,54.36Z"/>
    <path d="M215.76,2.16h10.92l43.56,68v-68h10.68V87.84H270.36L226.44,20.16V87.84H215.76Z"/>
    <path d="M447.6,12H419.28V2.16h67.8V12H458.76V87.84H447.6Z"/>
    <path d="M625.44,2.16h11V87.84h-11Z"/>
    <path d="M852.36,59.64a39.78,39.78,0,0,1-3.24,12.06,29.61,29.61,0,0,1-17.34,16A41.33,41.33,0,0,1,817.44,90a40.55,40.55,0,0,1-16-3,32,32,0,0,1-12-8.76,40.73,40.73,0,0,1-7.62-14.1,61.69,61.69,0,0,1-2.7-19A59.07,59.07,0,0,1,782,25.92a40.7,40.7,0,0,1,8-14.16A34.25,34.25,0,0,1,802.26,3a38.22,38.22,0,0,1,15.18-3,41.06,41.06,0,0,1,13.68,2A27.91,27.91,0,0,1,840.9,7.8a28.91,28.91,0,0,1,6.48,8.88,50.86,50.86,0,0,1,3.78,11.4l-10.8,1.8a43.72,43.72,0,0,0-2.82-8.46A21.35,21.35,0,0,0,833.1,15a17.93,17.93,0,0,0-6.48-4,26.89,26.89,0,0,0-9.18-1.38,23.63,23.63,0,0,0-19.74,9.78,33.5,33.5,0,0,0-5.22,11.16A54.42,54.42,0,0,0,790.68,45a54.42,54.42,0,0,0,1.8,14.46,34.21,34.21,0,0,0,5.22,11.22,23.38,23.38,0,0,0,8.4,7.2,25,25,0,0,0,11.46,2.52,29.54,29.54,0,0,0,10-1.5,17.94,17.94,0,0,0,6.84-4.26,20.18,20.18,0,0,0,4.26-6.6,38,38,0,0,0,2.28-8.4Z"/>
    <path d="M1030.56,90a42.3,42.3,0,0,1-16-2.94,34,34,0,0,1-12.48-8.64,39.56,39.56,0,0,1-8.1-14.1,59.07,59.07,0,0,1-2.88-19.2A58,58,0,0,1,994,25.92a40.91,40.91,0,0,1,8.16-14.16A34.31,34.31,0,0,1,1014.66,3a41.23,41.23,0,0,1,15.9-3,42.3,42.3,0,0,1,16,2.94A33.46,33.46,0,0,1,1059,11.58a40.14,40.14,0,0,1,8,14,58.53,58.53,0,0,1,2.88,19.14A60.79,60.79,0,0,1,1067.1,64a39.91,39.91,0,0,1-8,14.22A33.76,33.76,0,0,1,1046.7,87,41.88,41.88,0,0,1,1030.56,90Zm0-9.6a27.42,27.42,0,0,0,12.06-2.52,23.56,23.56,0,0,0,8.7-7.14,32.81,32.81,0,0,0,5.28-11.16A54.91,54.91,0,0,0,1058.4,45a54.42,54.42,0,0,0-1.8-14.46,32.82,32.82,0,0,0-5.34-11.22,24,24,0,0,0-8.7-7.2,27,27,0,0,0-12-2.52,27.72,27.72,0,0,0-12.18,2.52,23.84,23.84,0,0,0-8.76,7.2,32,32,0,0,0-5.28,11.22A56.19,56.19,0,0,0,1002.6,45a56.71,56.71,0,0,0,1.74,14.58,32,32,0,0,0,5.22,11.16,23.56,23.56,0,0,0,8.7,7.14A28,28,0,0,0,1030.56,80.4Z"/>
    <path d="M1212.6,2.16h28.08q9.6,0,15.72,2.16a26.29,26.29,0,0,1,9.66,5.58,18,18,0,0,1,4.86,7.74,29.82,29.82,0,0,1,1.32,8.76,26.8,26.8,0,0,1-3.42,13.92q-3.42,5.76-11.22,9l18,38.52h-12.36L1247,52a28.35,28.35,0,0,1-3.36.3q-1.8.06-3.24.06h-16.92V87.84H1212.6Zm29.16,40.68a33,33,0,0,0,9.48-1.14,14.28,14.28,0,0,0,5.88-3.24,11.18,11.18,0,0,0,3-5.1,24.92,24.92,0,0,0,.84-6.72,19.33,19.33,0,0,0-1-6.36,10.76,10.76,0,0,0-3.3-4.74,15.68,15.68,0,0,0-6.3-3,41.38,41.38,0,0,0-10.08-1h-16.8V42.84Z"/>
    <path d="M1415.16,2.16h28.68a50.85,50.85,0,0,1,15.66,2,24.72,24.72,0,0,1,9.72,5.52,18.82,18.82,0,0,1,4.92,8.1,34.35,34.35,0,0,1,1.38,9.78,36.55,36.55,0,0,1-1.44,10.32,20.56,20.56,0,0,1-5,8.64,24.7,24.7,0,0,1-9.72,5.88,47.14,47.14,0,0,1-15.48,2.16h-17.76V87.84h-10.92Zm28.56,43.08A37.38,37.38,0,0,0,1454,44a14.72,14.72,0,0,0,6.36-3.48,12.43,12.43,0,0,0,3.18-5.46,26.21,26.21,0,0,0,.9-7.14,24.52,24.52,0,0,0-1-7.2,10.85,10.85,0,0,0-3.36-5.16,15.82,15.82,0,0,0-6.42-3.06,42.2,42.2,0,0,0-10.14-1h-17.52V45.24Z"/>
    <path d="M1623.23,63.48q1.44,9,7.14,13t17,4a30.15,30.15,0,0,0,9.42-1.26,16.53,16.53,0,0,0,6.06-3.36,11.77,11.77,0,0,0,3.24-4.92,18.31,18.31,0,0,0,1-5.94,19.49,19.49,0,0,0-.6-4.86,10,10,0,0,0-2.34-4.26,15,15,0,0,0-4.8-3.36,31.43,31.43,0,0,0-7.86-2.28l-10.8-1.92a64.26,64.26,0,0,1-11.28-2.88,28.22,28.22,0,0,1-8.34-4.62,17.75,17.75,0,0,1-5.16-7,26.65,26.65,0,0,1-1.74-10.2,21,21,0,0,1,2.46-10.32,22.37,22.37,0,0,1,6.66-7.44,29.08,29.08,0,0,1,10-4.44A51.37,51.37,0,0,1,1645.67,0a46.44,46.44,0,0,1,14.22,1.86,26.64,26.64,0,0,1,9.3,5,21.18,21.18,0,0,1,5.46,7.14,41.62,41.62,0,0,1,2.82,8.22l-10.32,2a30.22,30.22,0,0,0-2.46-6.42,14.06,14.06,0,0,0-4-4.68,17.35,17.35,0,0,0-6.06-2.82,34.45,34.45,0,0,0-8.76-1,38,38,0,0,0-9.84,1.08,18.08,18.08,0,0,0-6.36,2.94,10.43,10.43,0,0,0-3.42,4.44,14.71,14.71,0,0,0-1,5.46,17.28,17.28,0,0,0,.78,5.46,9.73,9.73,0,0,0,2.64,4.08,14.63,14.63,0,0,0,4.86,2.88,44.64,44.64,0,0,0,7.44,2l12,2.16q13.32,2.52,19.56,8.34t6.24,17.1a23.72,23.72,0,0,1-2,9.66,22,22,0,0,1-5.88,7.86,28.19,28.19,0,0,1-9.78,5.28,44.57,44.57,0,0,1-13.8,1.92,58.31,58.31,0,0,1-13.74-1.5,30.93,30.93,0,0,1-10.74-4.74,24.35,24.35,0,0,1-7.32-8.28,30.14,30.14,0,0,1-3.36-12Z"/>
  </svg>
))

export default forwardRef((props, ref) => {
  const { language, setLanguage } = useContext(LanguageContext)

  return (
    <Fragment>
      {language === 'en' && <LogoEn ref={ref} />}
      {language === 'fr' && <LogoFr ref={ref} />}
    </Fragment>
  )
})
