import { Application, Graphics, Sprite, Texture, Text, Matrix, TextStyle, BLEND_MODES } from 'pixi.js';
import { Howl, Howler } from 'howler';
import Stats from 'stats.js';
import Tweener from 'tweener';

import { Mode } from './constants';
import TileScroller from './TileScroller';
import Tile from './Tile';

import bgMP3 from './audio/loops/bg.mp3'
import patt1MP3 from './audio/loops/patt1.mp3'
import patt2MP3 from './audio/loops/patt2.mp3'
import patt3MP3 from './audio/loops/patt3.mp3'
import patt4MP3 from './audio/loops/patt4.mp3'
import themMP3 from './audio/loops/them.mp3'

import transitionYouSound from './audio/triggers/transitionYou.mp3'
import transitionOthersSound from './audio/triggers/transitionOthers.mp3'
import tileLayoutChangeSound from './audio/triggers/tileLayoutChange.mp3'

////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////DOCS AND EXAMPLES FOR FACE TRACKER:
////////////////////////////////////////////////////https://www.npmjs.com/package/clmtrackr
////////////////////////////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////

export default class MainControl {
    constructor(canvas, settings) {
        this.app = new Application({
            view: canvas
        });
        this.loadedTiles = [];
        this.patternModes = [];

        this.stats = new Stats();
        //document.body.appendChild(this.stats.domElement);

        this.createGraphics();
        this.createAudio();
        this.app.ticker.add(this.updateGraphics,this);

        this.isTrackingEnabled = false;

        //this.showFacePromptInterval = -1;

        this._prevUserPatternChangeTime = Date.now()
        this._userPatternChangeTimeWait = 0;

        this.totalTransitionMs = 1000;
        this.isTransitionEnable = false;

        this._tileCols = 0;
        this._tileRows = 0;

        this._YOUWindowProps = false;

        this.isModeBegin = true;

        this.isPreviousFrameDetected = false;

        this.isMobile = settings.isMobile;

        this.newUserFrame = null;

        this._showFace = true;

        ////
        this.prevChangeModeTime = 0;
        this.manageAudioPlayback('applicationStarted');
    }

    setMode(mode) {
        //console.log('Changed mode to', mode);

        this._currentMode = mode;

        this.clearLoadedTiles();

        this.manageAudioPlayback('changeMode');

        if (this._currentMode === Mode.YOU) {
            this._tileScroller.resetPositions();
            //this.changeUserPatternMode();

            //this.soloAudioTrack(this._audioMixer.bgSound);
        } else if (this._currentMode === Mode.THEM) {
            //this._tileScroller.randomize();
            //this.randomizeTileScrollerLayout();
            this.changePatternScroll();
            this.userTileGraphics.visible = false;

            //this.soloAudioTrack(this._audioMixer.themSound);
        }
        //this.cancelFacePrompt();
        //this.facePromptText.visible = false;

        this.isTransitionEnable = false;
        this.isModeBegin = true;
        //this.resetTransitionTime();

        this.prevChangeModeTime = Date.now();
    }

    setSize(width, height) {

//        let tileDim = 200;
//
//        this._tileCols = Math.ceil(width / tileDim);
//        this._tileRows = Math.ceil(height / tileDim);

        this._tileCols = this.getTileColsAmount();
        this._tileRows = this.getTileRowsAmount();

//        this._tileScroller.setProperties(this._tileCols, this._tileRows, tileDim * this._tileCols, tileDim * this._tileRows);
        this._tileScroller.setProperties(this._tileCols, this._tileRows, width, height);
        this.app.renderer.resize(width, height);

//        this.facePromptText.x = (this.app.renderer.width / 2) - (this.facePromptText.width / 2);
//        this.facePromptText.y = (this.app.renderer.height / 2) - (this.facePromptText.height / 2);

        ////////////////////YOU WINDOW POSITION (For Switch Positioning)

        let userFrameW = Math.ceil(Math.min(this.app.renderer.width, this.app.renderer.height)*.5);
        let userFrameH = userFrameW;
        let offsetX = (this.app.renderer.width / 2) - (userFrameW / 2);
        let offsetY = (this.app.renderer.height / 2) - (userFrameH / 2);

        this._YOUWindowProps = {x:offsetX,y:offsetY,width:userFrameW,height:userFrameH};
    }

    setUserFrame(frame) {
        this.newUserFrame = frame;
    }
    updateUserTile() {

        if(this.newUserFrame == null) return;

        let frame = this.newUserFrame;

        this.userTile.setRecordedFrames([frame]);
        this.userSmallTile.setRecordedFrames([frame]);
        //this.userTile.updateFrame();

        let userFrameW = this._YOUWindowProps ? this._YOUWindowProps.width : 0;
        let userFrameH = this._YOUWindowProps ? this._YOUWindowProps.height : 0;

        let userFrameTexture = this.userTile.update(this.app.renderer, userFrameW, userFrameH, true, true);

        if(userFrameTexture.width) {

            if(!this.isTransitionEnable){
                this.resetTransitionTime();
                this.manageAudioPlayback('transitionYou');
            }
            this.isTransitionEnable = true;

            if(this.isTransitionEnable && this.isModeBegin && this.getTransitionProgress() >=1) {
                this.manageAudioPlayback('youModeBegin');
                this.isModeBegin = false;
            }

            let offsetX = this._YOUWindowProps ? this._YOUWindowProps.x : 0;
            let offsetY = this._YOUWindowProps ? this._YOUWindowProps.y : 0;

            let mtx = new Matrix();
            mtx.translate(offsetX, offsetY);

           // console.log("%cUPDATE USER TILE w="+this.userTileGraphics.width+"   h="+this.userTileGraphics.height,'background: blue; color: white;');

            this.userTileGraphics.clear();
            this.userTileGraphics.beginTextureFill({ texture: userFrameTexture, matrix: mtx });
            this.userTileGraphics.drawRect(offsetX, offsetY, userFrameW, userFrameH);
            this.userTileGraphics.endFill();
        }

        if(this.userTileGraphics.visible == false){
            this.userTileGraphics.visible = (this._currentMode == Mode.YOU) && this.isTrackingEnabled && this.getTransitionProgress() >=1 && this._showFace;
            //this.userTileGraphics.visible = (this._currentMode == Mode.YOU) && this.isTrackingEnabled;
        }

        /////
        if(frame.landmarks) {
            //this.cancelFacePrompt();

            if(!this.isPreviousFrameDetected){
                this.manageAudioPlayback("faceDetected");
            }
            this.isPreviousFrameDetected = true;

        } else {
            //this.scheduleFacePrompt();

            if(this.isPreviousFrameDetected){
                this.manageAudioPlayback("noFaceDetected");
            }
            this.isPreviousFrameDetected = false;
        }
        ////
        ////
//        if(Date.now() - this._prevUserPatternChangeTime >= this._userPatternChangeTimeWait){
//            this.changeUserPatternMode();
//        }
        this.newUserFrame = null;
    }

    addLoadedTile(recordedFrames) {
        if(this._currentMode === Mode.YOU)return;

        let newTile = new Tile();
        newTile.setRecordedFrames(recordedFrames);
        newTile.setId(this.loadedTiles.length);
        newTile.setPatternMode(this.getNextPatternMode());
        //this.loadedTiles.push(newTile);
        this.loadedTiles.unshift(newTile);

        /*this.app.stage.addChild(newTile);
        newTile.x = 100*this.loadedTiles.length*/

        newTile.setColors(this.getColors());

        if (this.loadedTiles.length > this.getMaxLoadedTilesSaved()) {
            //let removedTile = this.loadedTiles.shift();
            let removedTile = this.loadedTiles.pop();
            removedTile.dispose();
        }
//        this.randomizeTileScrollerLayout();
//        this._tileScroller.randomize();
    }
    clearLoadedTiles() {
        for (const tile of this.loadedTiles) {
            tile.dispose();
        }
        this.loadedTiles = [];
    }
    //////////////////////
    createGraphics() {

        this._tileScroller = new TileScroller();

        this.userTileGraphics = new Graphics();
        //this.userTileGraphics.blendMode = BLEND_MODES.MULTIPLY;

        this.userTile = new Tile(true);
        this.userTile.setColors(this.getColors());
        //this.userTile.setPatternMode(this.getNextPatternMode());
        this.userTileGraphics.visible = false

        this.tileGraphics = new Graphics();
        this.app.stage.addChild(this.tileGraphics);
        this.app.stage.addChild(this.userTileGraphics);

        let textStyle = new TextStyle();
        textStyle.align = "left";
        textStyle.fontSize = 48;
        textStyle.fill = 0xff0000;

        this.userSmallTile = new Tile();
        this.userSmallTile.setColors(this.getColors());
        //this.userSmallTile.setPatternMode(this.getNextPatternMode());

    }
    updateGraphics() {

//        console.log("1 ------ UPDATE GRAPHICS",this.loadedTiles.length);

        if(this._currentMode == Mode.YOU)this.updateUserTile();

        //console.log(this.getYOUWindowProps());

        this.stats.update();

//        for (const tile of this.loadedTiles) {
//            tile.updateFrame();
//        }

        if(this.isTransitionEnable && this.getTransitionProgress()>=1){

            ////pattern
            if(Date.now() - this._prevUserPatternChangeTime >= this._userPatternChangeTimeWait && this._currentMode == Mode.YOU){
                this.changeUserPatternMode();
            }
            ////scroll
            if(Math.random() < .005)this.changePatternScroll();
            ////mirror
            if(Math.random() < .009)this.changePatternMirrorMode();
            ////show face
            //this.changeShowFaceInPattern();
            //// pattern props
            if(Math.random() < .0075)this.changePatternProps();
        }

        if(this._currentMode === Mode.THEM)this._tileScroller.scroll();
        //this._tileScroller.scroll();

        let scrolledTiles = this._tileScroller.getTiles();

        this.tileGraphics.clear();

        let isFirstLoadedTile = true;

        ////tile offset to center
        let tileOffsetX = (this._tileScroller.getWidth() - this.app.renderer.width)/2;
        let tileOffsetY = (this._tileScroller.getHeight() - this.app.renderer.height)/2;

        for (const tile of scrolledTiles) {
            let loadedTile = this._currentMode == Mode.THEM ? this.loadedTiles[tile.id % this.loadedTiles.length] : this.userSmallTile;

            let tileTexture = null;
            if (loadedTile) {
                tileTexture = loadedTile.update(this.app.renderer, this._tileScroller.getTileWidth(), this._tileScroller.getTileHeight(),true,this._showFace);
                isFirstLoadedTile = false;

                if(this._currentMode == Mode.THEM) {
                    if(!this.isTransitionEnable){
                        this.resetTransitionTime();
                        this.manageAudioPlayback('transitionOthers');
                    }
                    this.isTransitionEnable = true;

                    if(this.isTransitionEnable && this.isModeBegin && this.getTransitionProgress() >=1) {
                        this.manageAudioPlayback('otherModeBegin');
                        this.isModeBegin = false;
                    }
                }
            }

            let isTileVisible = scrolledTiles.length * this.getTransitionProgress() > tile.id;

            if (tileTexture && isTileVisible && tileTexture.width) {

                let offsetX = (this._tileScroller.getTileWidth() / 2) - (tileTexture.width / 2);
                let offsetY = (this._tileScroller.getTileHeight() / 2) - (tileTexture.height / 2);

                let mtx = new Matrix();
//                if(!this.isMobile){
//                     mtx.scale(tile.isMirrorX ? -1 : 1, tile.isMirrorY ? -1 : 1);
//                }
                //mtx.translate(tile.x + offsetX - tileOffsetX, tile.y + offsetY - tileOffsetY);
                //mtx.rotate(this.getTransitionProgress());
                mtx.setTransform(
                  tile.isMirrorX ? tile.x + offsetX - tileOffsetX + tileTexture.width : tile.x + offsetX - tileOffsetX, // Translate X
                  tile.isMirrorY ? tile.y + offsetY - tileOffsetY + tileTexture.height : tile.y + offsetY - tileOffsetY, // Translate Y
                  0, // Pivot X
                  0, // Pivot Y
                  tile.isMirrorX ? -1 : 1, // Scale X
                  tile.isMirrorY ? -1 : 1, // Scale Y
                  0, // Rotation
                  0, // Skew X
                  0, // Skew Y
                );

                this.tileGraphics
                    .beginTextureFill({ texture: tileTexture, matrix: mtx })
                    .drawRect(tile.x - tileOffsetX, tile.y - tileOffsetY, this._tileScroller.getTileWidth(), this._tileScroller.getTileHeight())
                    .endFill();

            } else {
                let color = this.getColors()[tile.bgIndex];
                if(Math.random()<  (this._currentMode == Mode.YOU ? .75 : .5))this._tileScroller.changeTileBGIndex(tile.id);

                this.tileGraphics
                    .beginFill(color,1)
                    .drawRect(tile.x, tile.y, this._tileScroller.getTileWidth(), this._tileScroller.getTileHeight())
                    .endFill()
            }
        }
    }
    dispose() {
        this.clearLoadedTiles();
        this.tileGraphics = null;
        this.userTile.dispose();
        this.userSmallTile.dispose();
        this.userTileGraphics = null;
        // document.body.removeChild(this.stats.domElement);
        this.stats = null;
        this.app.ticker.remove(this.updateGraphics);
        //this.cancelFacePrompt();
        this.disposeSounds();
    }
    //////////////////////AUDIO
    createAudio(){

        ////BG SOUNDS THAT LOOP

        let loopVolume = .75;
        let loopRate = 1;

        this._audioMixer = {};
        this._audioMixer.bgSound = new Howl({ src: [bgMP3], loop:true, autoplay:true, volume:loopVolume*.25,rate:loopRate });
        this._audioMixer.patt1Sound = new Howl({ src: [patt1MP3], loop:true, autoplay:true, volume:loopVolume*.7,rate:loopRate });
        this._audioMixer.patt2Sound = new Howl({ src: [patt2MP3], loop:true, autoplay:true, volume:loopVolume*1,rate:loopRate });
        this._audioMixer.patt3Sound = new Howl({ src: [patt3MP3], loop:true, autoplay:true, volume:loopVolume*1,rate:loopRate });
        this._audioMixer.patt4Sound = new Howl({ src: [patt4MP3], loop:true, autoplay:true, volume:loopVolume*1,rate:loopRate });
        this._audioMixer.themSound = new Howl({ src: [themMP3], loop:true, autoplay:true, volume:loopVolume*.5,rate:loopRate });

        let audios = Object.values(this._audioMixer);
        for(const audioSprite of audios){
            audioSprite.initVolume = audioSprite.volume();
        }

        this.muteAllAudioTracks();

        ////EVENT SOUNDS THAT DON'T LOOP

        let triggerVolume = .75;

        this._audioTrigger = {};
//        this._audioTrigger.youModeBegin = new Howl({ src: [youModeBeginSound], loop:false, autoplay:false, volume:triggerVolume });
//        this._audioTrigger.otherModeBegin = new Howl({ src: [otherModeBeginSound], loop:false, autoplay:false, volume:triggerVolume });
        this._audioTrigger.transitionYou = new Howl({ src: [tileLayoutChangeSound], loop:false, autoplay:false, volume:triggerVolume*.8});
        this._audioTrigger.transitionOthers = new Howl({ src: [transitionOthersSound], loop:false, autoplay:false, volume:triggerVolume*.8 });
//        this._audioTrigger.applicationStarted = new Howl({ src: [applicationStartedSound], loop:false, autoplay:false, volume:triggerVolume });
//        this._audioTrigger.changeMode = new Howl({ src: [changeModeSound], loop:false, autoplay:false, volume:triggerVolume });
//        this._audioTrigger.faceDetected = new Howl({ src: [faceDetectedSound], loop:false, autoplay:false, volume:triggerVolume });
//        this._audioTrigger.facePromptVisible = new Howl({ src: [facePromptVisibleSound], loop:false, autoplay:false, volume:triggerVolume });
        this._audioTrigger.tileLayoutChange = new Howl({ src: [transitionYouSound], loop:false, autoplay:false, volume:triggerVolume*.2 });

    }
    soloAudioTrack(audio){
        let audios = Object.values(this._audioMixer);
        for(const audioSprite of audios){
            //audioSprite.volume(audioSprite == audio ? 1 : 0);
            audioSprite.volume(0);
        }
    }
    muteAllAudioTracks(){
        let audios = Object.values(this._audioMixer);
        for(const audioSprite of audios){
            audioSprite.volume(0);
        }
    }
    disposeSounds(){
        let audios = Object.values(this._audioMixer);
        for(const audioSprite of audios){
            audioSprite.unload();
        }
        this._audioMixer = null;

        let triggers = Object.values(this._audioTrigger);
        for(const audioTriggerSprite of triggers){
            audioTriggerSprite.unload();
        }
        this._audioTrigger = null;
    }
    manageAudioPlayback(eventId, param){

        switch(eventId){
            case 'applicationStarted':
//                this._audioTrigger.tileLayoutChange.seek(0);
//                this.setRandomPanning(this._audioTrigger.tileLayoutChange);
//                this._audioTrigger.tileLayoutChange.rate(.5 + (4 - .5)*Math.random());
//                this._audioTrigger.tileLayoutChange.play();
                this._audioMixer.bgSound.volume(this._audioMixer.bgSound.initVolume);
                break;
            case 'faceDetected':
                /*this._audioTrigger.faceDetected.seek(0);
                this.setRandomPanning(this._audioTrigger.faceDetected);
                this._audioTrigger.faceDetected.play();*/
                break;
            case 'noFaceDetected':
//                this._audioTrigger.noFaceDetected.seek(0);
//                this.setRandomPanning(this._audioTrigger.noFaceDetected);
//                this._audioTrigger.noFaceDetected.play();
//                    this.soloAudioTrack(this._audioMixer.noDetectSound);
                break;
            case 'facePromptVisible':
                /*this._audioTrigger.facePromptVisible.seek(0);
                this.setRandomPanning(this._audioTrigger.facePromptVisible);
                this._audioTrigger.facePromptVisible.play();*/
                break;
            case 'patternModeChanged':
                this._audioMixer.patt1Sound.volume(param == 0 && this._currentMode == Mode.YOU ? this._audioMixer.patt1Sound.initVolume : 0);
                this._audioMixer.patt2Sound.volume(param == 1 && this._currentMode == Mode.YOU ? this._audioMixer.patt2Sound.initVolume : 0);
                this._audioMixer.patt3Sound.volume(param == 2 && this._currentMode == Mode.YOU ? this._audioMixer.patt3Sound.initVolume : 0);
                this._audioMixer.patt4Sound.volume(param == 3 && this._currentMode == Mode.YOU ? this._audioMixer.patt4Sound.initVolume : 0);
                break;
            case 'youModeBegin':
                this._audioTrigger.transitionYou.stop();
                this._audioTrigger.transitionOthers.stop();

                /*this._audioTrigger.youModeBegin.seek(0);
                this.setRandomPanning(this._audioTrigger.youModeBegin);
                this._audioTrigger.youModeBegin.play();*/

                this._audioMixer.patt1Sound.volume(param == 0 && this._currentMode == Mode.YOU ? this._audioMixer.patt1Sound.initVolume : 0);
                this._audioMixer.patt2Sound.volume(param == 1 && this._currentMode == Mode.YOU ? this._audioMixer.patt2Sound.initVolume : 0);
                this._audioMixer.patt3Sound.volume(param == 2 && this._currentMode == Mode.YOU ? this._audioMixer.patt3Sound.initVolume : 0);
                this._audioMixer.patt4Sound.volume(param == 3 && this._currentMode == Mode.YOU ? this._audioMixer.patt4Sound.initVolume : 0);

                this._audioMixer.themSound.volume(0);
                break;
            case 'otherModeBegin':
                this._audioTrigger.transitionYou.stop();
                this._audioTrigger.transitionOthers.stop();

                /*this._audioTrigger.otherModeBegin.seek(0);
                this.setRandomPanning(this._audioTrigger.otherModeBegin);
                this._audioTrigger.otherModeBegin.play();*/

                this._audioMixer.patt1Sound.volume(0);
                this._audioMixer.patt2Sound.volume(0);
                this._audioMixer.patt3Sound.volume(0);
                this._audioMixer.patt4Sound.volume(0);

                this._audioMixer.themSound.volume(this._audioMixer.themSound.initVolume);
                break;
            case 'tileLayoutChange':
                this._audioTrigger.tileLayoutChange.seek(0);
                this.setRandomPanning(this._audioTrigger.tileLayoutChange);
                this._audioTrigger.tileLayoutChange.rate(.5 + (4 - .5)*Math.random());
                this._audioTrigger.tileLayoutChange.play();
                break;
            case 'transitionYou':
                this._audioTrigger.transitionYou.stop();
                this._audioTrigger.transitionOthers.stop();

                this._audioTrigger.transitionYou.seek(0);
                this.setRandomPanning(this._audioTrigger.transitionYou);
                this._audioTrigger.transitionYou.play();
                this.setRandomPanning(this._audioTrigger.transitionYou);
                break;
            case 'transitionOthers':
                this._audioTrigger.transitionYou.stop();
                this._audioTrigger.transitionOthers.stop();

                this._audioTrigger.transitionOthers.seek(0);
                this.setRandomPanning(this._audioTrigger.transitionOthers);
                this._audioTrigger.transitionOthers.play();
                this.setRandomPanning(this._audioTrigger.transitionOthers);
                break;
            case 'changeMode':
                /*this._audioTrigger.tileLayoutChange.seek(0);
                this.setRandomPanning(this._audioTrigger.tileLayoutChange);
                this._audioTrigger.tileLayoutChange.rate(.5 + (4 - .5)*Math.random());
                this._audioTrigger.tileLayoutChange.play();*/

                this._audioMixer.themSound.volume(0);
                this._audioMixer.patt1Sound.volume(0);
                this._audioMixer.patt2Sound.volume(0);
                this._audioMixer.patt3Sound.volume(0);
                this._audioMixer.patt4Sound.volume(0);
                break;
        }
    }
    setRandomPanning(sound){
        let min = -1;
        let max = 1;
        let pan = min + (max - min) * Math.random();
        sound.stereo(pan);
    }
    //////////////////////ENABLE / DISABLE TRACKING
    onTrackingEnabled() {
        this.isTrackingEnabled = true;
        if(this._currentMode === Mode.YOU) {
            //this.userTileGraphics.visible = true;
        }
        //this.cancelFacePrompt();
    }

    onTrackingDisabled() {
        this.isTrackingEnabled = false;
        if(this._currentMode === Mode.YOU) {
            //this.userTileGraphics.visible = false;
        }
        //this.cancelFacePrompt();
    }
    //////////////////////TRANSITION
    resetTransitionTime(){
        this.transitionInitTime = Date.now();
    }
    getTransitionProgress(){
        return this.isTransitionEnable ? (Date.now() - this.transitionInitTime) / this.totalTransitionMs : 0;
    }
    /////////////////////MOBILE OR DESKTOP
    getTileColsAmount(){
        let isMobile = this.isMobile;
        let tileDims = [8, 10, 12];
        let tileDimsMobile = [4, 6, 8];

        if (Date.now() - this.prevChangeModeTime > 4000) {
          tileDims.push(4, 6);
          tileDimsMobile.push(2);
        }

        return isMobile ? tileDimsMobile[Math.floor(Math.random()*tileDimsMobile.length)] : tileDims[Math.floor(Math.random()*tileDims.length)];
    }
    getTileRowsAmount(){
        const width = this.app.renderer.width / this._tileCols;
        return Math.round(this.app.renderer.height / width);
    }
    getMaxLoadedTilesSaved(){
        let isMobile = this.isMobile;
        return isMobile ? 10 : 15;
    }
    /////////////////////PUBLIC METHODS FOR SWITCH POSITIONING
    getYOUWindowProps(){
        //return {x:this._YOUWindowProps.x,y:this._YOUWindowProps.y-1,width:this._YOUWindowProps.width,height:this._YOUWindowProps.height};
        return this._YOUWindowProps;
    }
    getYOUWindowInstructionsVisibility(){
        return false;
    }
    /////////////////////Colors
    getColors(){
        //return [0x00f9c3,0x0000ff,0x33e2ff,0x6600ff];
        return [0xffffff,0x00ffff,0x0000ff,0xff0000];
    };
    ////////////////////Patterns
    /*randomizeTileScrollerLayout(){
        this.manageAudioPlayback("tileLayoutChange");

        this._tileCols = this.getTileColsAmount();
        this._tilesRows = this._tileCols;

        this._tileScroller.setProperties(this._tileCols, this._tilesRows, this.app.renderer.width, this.app.renderer.height);

        console.log("COLS, ROWS",this._tileCols,this._tilesRows);

        this._tileScroller.randomize();
    }*/
    getNextPatternMode(){
        if(this.patternModes.length == 0){
           //let modes = [0,1,2,3];
            let modes = [0,2,3];
            while(modes.length > 0) {
                let index = Math.floor(Math.random() * modes.length);
                this.patternModes.push(modes.splice(index,1)[0]);
            }
        }
        return this.patternModes.shift();
    }
    changeUserPatternMode(){
//        console.log("changeUserPatternMode");
        let mode = this.getNextPatternMode();
        this.manageAudioPlayback('patternModeChanged', mode);
        this.userTile.setPatternMode(mode);
        this.userSmallTile.setPatternMode(mode);

        this._prevUserPatternChangeTime = Date.now();

        let minTime = 3000;
        let maxTime = 10000;

        this._userPatternChangeTimeWait = minTime + (Math.random()*(maxTime - minTime));
    }
    changePatternScroll(){
        this.manageAudioPlayback('tileLayoutChange');
        this._tileScroller.randomize();
    }
    changePatternProps(){
        this.manageAudioPlayback('tileLayoutChange');
        this._tileCols = this.getTileColsAmount();
        this._tilesRows = this.getTileRowsAmount();

        this._tileScroller.setProperties(this._tileCols, this._tilesRows, this.app.renderer.width, this.app.renderer.height);
    }
    changePatternMirrorMode(){
        this.manageAudioPlayback('tileLayoutChange');
        this._tileScroller.changeMirrorMode();
    }
    changeShowFaceInPattern(){
        //console.log("changeShowFaceInPattern");
        this._showFace = this._showFace ? Math.random() < .9 : Math.random() < .01;
        //console.log("changeShowFaceInPattern",this._showFace);
    }
    ////////////////////External Methods
    onNoFaceDetected() {
      // Play sound
        //console.log('%cNO FACE DETECTED FOR TOO LONG', 'background: red;');
        //this.facePromptText.visible = true;
        this.manageAudioPlayback("facePromptVisible");
    }

    onFaceDetected() {
      // Do something?
        //this.facePromptText.visible = false;
    }
}
