import { Application, Container, Graphics, Sprite, Texture, Text, Rectangle, RenderTexture, BaseRenderTexture, BLEND_MODES } from 'pixi.js';
import { AdjustmentFilter } from 'pixi-filters';

export default class Tile extends Container {
    constructor(isUserTile = false) {
        super();
        this._id = -1;

        this._totalFrames = 0;
        this._frames = [];

        this._patternMode = 0;

        this._framesPerSecond = 10;
        this._initTime = Date.now();        
        this._currentFrame = -1;

        this._sprite = new Sprite();
        this.addChild(this._sprite);

        this._graphics = new Graphics();
        //this._graphics.blendMode = BLEND_MODES.ADD;

        this.addChild(this._graphics);

        this._cachedTexture = RenderTexture.create(256,256);
        this._isUserTile = isUserTile;
        
        let fltr = new AdjustmentFilter({saturation:0,contrast:1.5});
        
        this._sprite.filters = [fltr];
        
        this._colors = [0xffffff,0xff0000,0x00ff00,0x0000ff];
        
        this._isRecordedFramesUpdated = false; 
        
        this._prevUpdateW = -1;
        this._prevUpdateH = -1;
        
        this._prevIncludeFace = false;
        this._prevIncludeLandmarks = false;
    }

    setId(id) {
        this._id = id;
    }
    getId() {
        return this._id;
    }

    setRecordedFrames(frames) {        
        this._totalFrames = frames.length;
        this._frames = frames;
        this._isRecordedFramesUpdated = true;
    }

    updateFrame() {        
        if(this._frames.length == 0 || this.getCurrentFrame() == NaN)return false;
        
        let isFrameUpdated = false;
        
        let prevFrame = this._currentFrame;
        this._currentFrame = this.getCurrentFrame();
        
        if(this._currentFrame != prevFrame || this._isRecordedFramesUpdated) {        
            let currentImage = this._frames[this._currentFrame].image;
            if (this._sprite.texture) this._sprite.texture.destroy(true);
            this._sprite.texture = Texture.from(currentImage);
            isFrameUpdated = true;
            this._isRecordedFramesUpdated = false;
        }        
        return isFrameUpdated;
    }
    drawPattern() {
        
        if(this._frames[this.getCurrentFrame()] == null)return;
        
        let landmarks = this._frames[this.getCurrentFrame()].landmarks;
        
        this._graphics.clear();
        
        let patternColors = [0xffffff,0xffffff,0xffffff,0xffffff];

        if (landmarks) {
            
//            this._graphics.beginFill(0xffffff,1);
//            this._graphics.drawRect(0,0,this._sprite.width,this._sprite.height);
//            this._graphics.endFill();
            
            let isMove = true;
            
            let userLineThinkness = 2;
            let tileLineThickness = 1;
            
            let lineThickness = this._isUserTile ? userLineThinkness : tileLineThickness;
            let useNativeLine = false;
            
            ////////pattern in view area            
            let left = (this._sprite.width - this._prevUpdateW) / 2;
            let top = (this._sprite.height - this._prevUpdateH) / 2;
            let right = left + this._prevUpdateW;
            let bottom = top + this._prevUpdateH;
            ////////
            let eyeL = null;
            let eyeR = null;
            let eyeBrowL = null;
            let eyeBrowR = null;
            let noseUp = null;
            let noseDown = null;
            let mouth = null;
            let head = null;
            
            let drawChance = .95;
            
            //this._patternMode = 2;
            
            switch(this._patternMode) {
                case 0:
                    
                    eyeL = this.getLandmarkFaceArea("eyeL",landmarks);
                    eyeR = this.getLandmarkFaceArea("eyeR",landmarks);
                    mouth = this.getLandmarkFaceArea("mouth",landmarks);
                    
                   /* this._graphics.beginFill(this._colors[this._patternMode],1);
                    //this._graphics.lineStyle(lineThickness,this._colors[this._patternMode],1,.5,useNativeLine);
                    isMove = true;

                    for (const [x, y] of mouth.all) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            if(Math.random()<drawChance)this._graphics.lineTo(x,y);
                        }
                    }
                    this._graphics.endFill();*/
                    
                    if(Math.random() < drawChance) {
                        this._graphics.beginFill(patternColors[this._patternMode],1);
                        this._graphics.drawCircle(eyeL.center.x * this._sprite.scale.x,eyeL.center.y * this._sprite.scale.y,Math.max(eyeL.width * this._sprite.scale.x,eyeL.height * this._sprite.scale.y)*.6);
                        this._graphics.endFill();
                    }
                    
                    if(Math.random() < drawChance) {
                        this._graphics.beginFill(patternColors[this._patternMode],1);
                        this._graphics.drawCircle(eyeR.center.x * this._sprite.scale.x,eyeR.center.y * this._sprite.scale.y,Math.max(eyeR.width * this._sprite.scale.x,eyeR.height* this._sprite.scale.y)*.6);
                        this._graphics.endFill();
                    }
                    if(Math.random() < drawChance) {
                        this._graphics.beginFill(patternColors[this._patternMode],1);
                        this._graphics.drawCircle(mouth.center.x * this._sprite.scale.x,mouth.center.y * this._sprite.scale.y,Math.min(mouth.width * this._sprite.scale.x,mouth.height* this._sprite.scale.y));
                        this._graphics.endFill();
                    }
                    break;
                case 1:
                    
                    this._graphics.lineStyle(lineThickness,patternColors[this._patternMode],1,.5,useNativeLine);
                    mouth = this.getLandmarkFaceArea("mouth",landmarks);
                    eyeL = this.getLandmarkFaceArea("eyeL",landmarks);
                    eyeR = this.getLandmarkFaceArea("eyeR",landmarks);
                    
                    this._graphics.beginFill(patternColors[this._patternMode],1);
                    //this._graphics.drawRect((mouth.center.x - mouth.width/2) * this._sprite.scale.x,(mouth.center.y - mouth.height/2) * this._sprite.scale.y,mouth.width * this._sprite.scale.x,mouth.height * this._sprite.scale.y);
                    
                     isMove = true;
                    
                    for (const [x, y] of mouth.all) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            if(Math.random()<drawChance)this._graphics.lineTo(x,y);
                        }
                    }
                    
                    isMove = true;
                    
                    for (const [x, y] of eyeL.all) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            if(Math.random()<drawChance)this._graphics.lineTo(x,y);
                        }
                    }
                    
                    isMove = true;
                    
                    for (const [x, y] of eyeR.all) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            if(Math.random()<drawChance)this._graphics.lineTo(x,y);
                        }
                    }
                    
                    this._graphics.endFill();
                    
                    break;
                 case 2:
                    
                    //this._graphics.lineStyle(tileLineThickness,patternColors[this._patternMode],1,.5,useNativeLine);
                    /*mouth = this.shuffleArray(this.getLandmarkFaceArea("mouth",landmarks).all);
                    eyeL = this.shuffleArray(this.getLandmarkFaceArea("eyeL",landmarks).all);
                    eyeR = this.shuffleArray(this.getLandmarkFaceArea("eyeR",landmarks).all);*/
                    
                    head = this.getLandmarkFaceArea("head",landmarks).all.concat(this.getLandmarkFaceArea("noseDown",landmarks).all);
                    
                    let repeatAmount = 2;
                    
                    for(let i = 0; i < repeatAmount; i++){
                        head = head.concat(head);
                    }
                    head = this.shuffleArray(head);
                    
                    /*isMove = true;
                    
                    for (const [x, y] of mouth) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            //if(Math.random()<1)this._graphics.lineTo(x,y);
                        }
                    }
                    
                    isMove = true;
                    
                    for (const [x, y] of eyeL) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            //if(Math.random()<1)this._graphics.lineTo(x,y);
                        }
                    }
                    
                    isMove = true;
                    
                    for (const [x, y] of eyeR) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            //if(Math.random()<1)this._graphics.lineTo(x,y);
                        }
                    }*/
                    
                    this._graphics.lineStyle(tileLineThickness,patternColors[this._patternMode],1,.5,useNativeLine);
                    
                    isMove = true;
                    
                   // head = head.sort((a, b) => a[Math.random() < .5 ? 0 : 1] - b[Math.random() < .5 ? 0 : 1]);
                   // head = head.sort((a, b) => a[Math.random() < .5 ? 0 : 1] - b[Math.random() < .5 ? 0 : 1]);
                    
                    for (const [x, y] of head) {
                        x *= this._sprite.scale.x;
                        y *= this._sprite.scale.y;

                        if(isMove){
                            this._graphics.moveTo(x,y);
                            isMove = false;
                        } else {
                            if(Math.random()<drawChance*.25)this._graphics.lineTo(x,y);
                            //isMove = true;
                        }
                    }
                    
//                    for (const [x, y] of landmarks) {
//                        x *= this._sprite.scale.x;
//                        y *= this._sprite.scale.y;                        
//                        if(Math.random()<drawChance)this._graphics.drawRect(x-1.5,y-1.5,3,3);
//                    }
                    
//                    isMove = true;
//                    
//                    let points = this.shuffleArray(landmarks);
//
//                    for (const [x, y] of points) {
//
//                        x *= this._sprite.scale.x;
//                        y *= this._sprite.scale.y;
//
//                        if(isMove){
//                            this._graphics.moveTo(x,y);
//                            isMove = false;
//                        } else {
//                            if(Math.random()<drawChance*.45)this._graphics.lineTo(x,y);
//                        }
//                    }
                    break;
                case 3:
                    this._graphics.lineStyle(lineThickness,patternColors[this._patternMode],1,.5,useNativeLine);
                    
                    mouth = this.getLandmarkFaceArea("mouth",landmarks);
                    eyeL = this.getLandmarkFaceArea("eyeL",landmarks);
                    eyeR = this.getLandmarkFaceArea("eyeR",landmarks);
                    noseUp = this.getLandmarkFaceArea("noseUp",landmarks);
                    //noseDown = this.getLandmarkFaceArea("noseDown",landmarks);
                    eyeBrowL = this.getLandmarkFaceArea("eyeBrowL",landmarks);
                    eyeBrowR = this.getLandmarkFaceArea("eyeBrowR",landmarks);
                    head = this.getLandmarkFaceArea("head",landmarks,false);
                    
//                    let parts = [mouth.all,eyeL.all,eyeR.all,noseUp.all,noseDown.all,eyeBrowL.all,eyeBrowR.all,head.all];
                     let parts = [mouth.all,eyeL.all,eyeR.all,noseUp.all,eyeBrowL.all,eyeBrowR.all,head.all];
                    
                    for(const part of parts) {
                        
                        isMove = true;
                        
                        for (const [x, y] of part) {

                            x *= this._sprite.scale.x;
                            y *= this._sprite.scale.y;

                            if(isMove){
                                this._graphics.moveTo(x,y);
                                isMove = false;
                            } else {
                                if(Math.random()<drawChance)this._graphics.lineTo(x,y);
                            }
                        }
                    }
                    break;
            }
        }
    }

    getCurrentFrame() {
        let lifetimeSecs = (Date.now() - this._initTime) / 1000;
        let framesTotalLifetime = lifetimeSecs * this._framesPerSecond;
        return Math.floor(framesTotalLifetime % this._totalFrames);
    }
    getFrameRate(){
        return this._framerate;
    }
    
    setPatternMode(modeId){
        this._patternMode = modeId;
    }
    getPatternMode(){
        return this._patternMode;
    }
    update(renderer, w, h, includeLandmarks = true, includeFace = true, force = false) {
        
        let frameChanged = this.updateFrame();
        
        let dimensionsChanged = (this._prevUpdateW != w) || (this._prevUpdateH != h);
        this._prevUpdateW = w;
        this._prevUpdateH = h;
        
        let elementsChanged = (this._prevIncludeLandmarks != includeLandmarks) || (this._prevIncludeFace != includeFace);
        this._prevIncludeLandmarks = includeLandmarks;
        this._prevIncludeFace = includeFace;
        
        if (frameChanged || dimensionsChanged || elementsChanged) {

            this._sprite.visible = includeFace;

            this._sprite.width = w
            this._sprite.height = h;

            this._sprite.scale.x = Math.max(this._sprite.scale.x, this._sprite.scale.y);
            this._sprite.scale.y = this._sprite.scale.x;

            if(includeLandmarks) {
                this.drawPattern();
            }
            this._graphics.visible = includeLandmarks;
            
            if (dimensionsChanged) {
                this._cachedTexture.resize(this._sprite.width, this._sprite.height, true);
            }
            renderer.render(this, this._cachedTexture);
        } else {
            
        }

        return this._cachedTexture;
    }
    
    dispose() {
        if (this._cachedTexture) this._cachedTexture.destroy(true);
        if (this._sprite.texture) this._sprite.texture.destroy(true);
        this._frames = [];
        this._sprite = null;
        this._graphics = null;
        this.destroy({
            children: true,
            texture: true,
            baseTexture: true
        });
    }
    setColors(colors){
        //colors = [0x000000,0x000000,0x000000,0x000000];
        //colors = [0xffffff,0xffffff,0xffffff,0xffffff];
        this._colors = colors;
    }
    /////////////////
    getLandmarkFaceArea(dataType,landmarks, extraParam = true) {
        
        let toReturn = {all:null,width:null,height:null,center:{}};
        let dims = null;
        
        switch(dataType){
            case "eyeL":
                //toReturn.all = landmarks.slice(23,23+4);
                toReturn.all = [landmarks[23],landmarks[63],landmarks[24],landmarks[64], landmarks[25], landmarks[65], landmarks[26], landmarks[66]];
                toReturn.all.push(toReturn.all[0]);                
                
                dims = this.findDimensions(toReturn.all);
                toReturn.width = dims.width;
                toReturn.height = dims.height;
                toReturn.center.x = landmarks[27][0];
                toReturn.center.y = landmarks[27][1];
                
                break;
            case "eyeR":
                //toReturn.all = landmarks.slice(28,28+4); 
                toReturn.all = [landmarks[30],landmarks[68],landmarks[29],landmarks[67], landmarks[28], landmarks[70], landmarks[31], landmarks[69]];
                toReturn.all.push(toReturn.all[0]);
                
                dims = this.findDimensions(toReturn.all);
                toReturn.width = dims.width;
                toReturn.height = dims.height;
                toReturn.center.x = landmarks[32][0];
                toReturn.center.y = landmarks[32][1];
                break;
             case "eyeBrowR":
                toReturn.all = landmarks.slice(15,15+4);               
                
                dims = this.findDimensions(toReturn.all);
                toReturn.width = dims.width;
                toReturn.height = dims.height;
                toReturn.center = dims.center;                
                break;
            case "eyeBrowL":
                toReturn.all = landmarks.slice(19,19+4);               
                
                dims = this.findDimensions(toReturn.all);
                toReturn.width = dims.width;
                toReturn.height = dims.height;
                toReturn.center = dims.center;                
                break;
            case "noseUp":
                 toReturn.all = [landmarks[33],landmarks[41],landmarks[62]];
                 dims = this.findDimensions(toReturn.all);
                 toReturn.width = dims.width;
                 toReturn.height = dims.height;
                 toReturn.center.x = landmarks[41][0];
                 toReturn.center.y = landmarks[41][1];
                break;
             case "noseDown":
                toReturn.all = [landmarks[34], landmarks[35], landmarks[36], landmarks[42], landmarks[37], landmarks[43], landmarks[38],landmarks[39],landmarks[40]];
                 dims = this.findDimensions(toReturn.all);
                 toReturn.width = dims.width;
                 toReturn.height = dims.height;
                 toReturn.center.x = landmarks[62][0];
                 toReturn.center.y = landmarks[62][1];
                break;
            case "mouth":
                toReturn.all = landmarks.slice(44,44+12);
                toReturn.all.push(toReturn.all[0]);
                dims = this.findDimensions(toReturn.all);
                toReturn.width = dims.width;
                toReturn.height = dims.height;
                toReturn.center = dims.center;
                break;
            case "head":
                let chin = landmarks.slice(0,0+15);
                if(extraParam) {
                    let eyeBrowL = this.getLandmarkFaceArea("eyeBrowL",landmarks).all;
                    let eyeBrowR = this.getLandmarkFaceArea("eyeBrowR",landmarks).all.reverse();
                    toReturn.all = chin.concat(eyeBrowL,eyeBrowR);
                } else {
                    toReturn.all = chin;
                }
                //toReturn.all.push(chin[0]);
                dims = this.findDimensions(toReturn.all);
                toReturn.width = dims.width;
                toReturn.height = dims.height;
                toReturn.center.x = landmarks[37][0];
                toReturn.center.y = landmarks[37][1];
                break;
        }        
        return toReturn;
    }
    findDimensions(points){
        
        /*let minX = points[0][0];
        let maxX = minX;
        
        let minY = points[0][1];
        let maxY = minY;
        
        for (const [x, y] of points) {
            minX = Math.min(minX,x);
            maxX = Math.max(maxX,x);
            
            minY = Math.min(minY,y);
            maxY = Math.max(maxY,y);
        } */
        let copy = points.slice();
        copy.sort((a, b) => a[0] - b[0]);
        
        let minX = copy[0][0];
        let maxX = copy[copy.length-1][0];
        
        copy.sort((a, b) => a[1] - b[1]);
        
        let minY = copy[0][1];
        let maxY = copy[copy.length-1][1];
        
        return {center:{x:(maxX-minX)/2 + minX, y: (maxY-minY)/2 + minY}, width:maxX-minX,height:maxY-minY};
    }
    findAverage(points){
        let sumX = 0;
        let sumY = 0;

        for (const [x, y] of points) {
            sumX+=x;
            sumY+=y;
        }
        return {x: sumX / points.length, y: sumY / points.length};
    }
    shuffleArray(array){
        let copy = array.slice();
        let toReturn = [];
        while(copy.length){
            let randIndex = Math.floor(copy.length*Math.random());
            toReturn.push(copy.splice(randIndex,1)[0]);
        }
        return toReturn;
    }
}
