import styled from 'styled-components'

export default styled.button`
  text-transform: uppercase;
  letter-spacing: 0.15em;
  border-bottom: 1px solid black;
  transition: border-color 150ms linear;
  background: transparent;

  :hover {
    border-color: transparent;
  }

  ${({ activable, active }) => activable && !active && `
    border-color: transparent;
  `}

  ${({ inverted }) => inverted && `
    background: black;
    color: white;
    border-color: white;
  `}
`
