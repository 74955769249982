import { createGlobalStyle } from 'styled-components'
import aktivGroteskRegular from '../assets/AktivGrotesk_Regular.ttf'

export default createGlobalStyle`
  :root {
    --margin: 60px;
  }

  @media (max-width: 1024px) {
    :root {
      --margin: 32px;
    }
  }

  @font-face {
    font-family: 'Aktiv Grotesk';
    src: url('${aktivGroteskRegular}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    font-weight: inherit;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    border: none;
    outline: none;
    box-sizing: border-box;
  }

  ::selection {
    background: rgba(0, 0, 0, 0.25);
  }

  body {
    background: white;
    color: #222;
    font-family: 'Aktiv Grotesk', sans-serif;
    font-weight: normal;
    font-size: 12px;
    // overflow: hidden;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }

  button {
    cursor: pointer;
    -webkit-appearance: none;
  }
`
