import crypto from 'crypto'

export const getApiHmac = (method, url, body = undefined, identifier = 'HMAC') => {
    const hmac = crypto.createHmac('sha256', window.__apiKey)
    const time = Date.now().toString()

    hmac.update(time)
    hmac.update(method)
    hmac.update(url)

    if (typeof body !== 'undefined') {
        const contentHash = crypto.createHash('md5')
        contentHash.update(JSON.stringify(body))

        hmac.update(contentHash.digest('hex'))
    }

    const result = `${identifier} ${time}:${hmac.digest('hex')}`

    return result
}
