import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { isUsingMobileDevice } from '../lib/detect'
import { useAnalytics, useScreenview } from './analytics'
import { translate } from './language'
import LanguageSwitcher from './LanguageSwitcher'
import { PrimaryButtonArea, SecondaryButtonArea } from './layouts'
import InfoScreen from './InfoScreen'
import PageContainer from './ui/PageContainer.jsx'
import ActionButton from './ui/ActionButton'
import LinkButton from './ui/LinkButton'
import BlinkingLogo from './ui/BlinkingLogo'
import Header from './ui/Header'

const StyledLogoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: var(--margin);
  right: var(--margin);
  transform: translateY(-100%);
`

const LandingActionArea = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  padding: var(--margin);
`

const StyledSlogan = styled.p`
  margin-bottom: calc(2 * var(--margin));
`

export default () => {
  const visitor = useAnalytics()
  const [ infoVisible, setInfoVisible ] = useState(false)
  const history = useHistory()

  useScreenview('Landing')

  const enter = useCallback(() => {
    visitor.event('Landing / Info', 'Click Enter').send()
    history.push(`/chapter/01`)
  }, [])

  const displayInfo = useCallback(() => {
    setInfoVisible(true)
    visitor.event('Landing / Info', 'Click Info').send()
  }, [])

  return (
    <PageContainer>
      <LanguageSwitcher
        actionElement={<LinkButton onClick={displayInfo} >Info</LinkButton>}
      />

      <Header />

      <StyledLogoWrapper>
        <BlinkingLogo />
      </StyledLogoWrapper>

      <LandingActionArea>
        <StyledSlogan>{translate('slogan')}</StyledSlogan>
        <ActionButton solid onClick={enter}>{translate('enter')}</ActionButton>
      </LandingActionArea>

      {infoVisible && (
        <InfoScreen onClose={() => setInfoVisible(false)} />
      )}
    </PageContainer>
  )
}
